import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import firebase from 'firebase/app';
import 'firebase/database';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
		paddingBottom: 20,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20,
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '50%',
		borderRadius: '10px',
		outline: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	text: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
}));

// eslint-disable-next-line react/prop-types
const CategoriesModal = ({ open, onClose }) => {
	const classes = useStyles();
	const [tags, setTags] = useState([]);
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		const tagsRef = firebase.database().ref('faculties');
		tagsRef.on('value', (snapshot) => {
			const existingTags = snapshot.val();
			if (existingTags) {
				const tagsArray = Object.keys(existingTags);
				setTags(tagsArray);
			}
		});
		return () => tagsRef.off();
	}, []);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleInputKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			addTag(inputValue.trim());
		}
	};

	const handleDeleteTag = (tagToDelete) => () => {
		setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
		firebase.database().ref('faculties').child(tagToDelete).remove();
	};

	const addTag = async (tag) => {
		if (tag !== '' && !tags.includes(tag)) {
			setTags([...tags, tag]);
			await firebase.database().ref('faculties').child(tag).set(true);
			setInputValue('');
		}
	};

	return (
		<Modal
			open={open}
			onClose={() => onClose('faculties')}
			className={classes.modal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<h2>Faculties</h2>
					<TextField
						label='Enter Faculties'
						placeholder='Enter Faculties'
						value={inputValue}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
						fullWidth
					/>
					<div className={classes.root}>
						{tags.map((tag) => (
							<Chip key={tag} label={tag} onDelete={handleDeleteTag(tag)} />
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default CategoriesModal;
