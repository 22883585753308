import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import firebase from 'firebase/app';
import 'firebase/database';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import { DeleteForever } from '@material-ui/icons';

const MediaCard = ({ title, description, videoUrl, id }) => {
	const { width } = useWindowDimensions();

	const useStyles = makeStyles((theme) => ({
		root: {
			backgroundColor: 'white',
			borderRadius: 10,
			maxWidth: '100%',
			width: 345,
			[theme.breakpoints.down('sm')]: {
				width: (width * 85) / 100,
				minWidth: '100%',
				marginBottom: theme.spacing(2),
			},
			shadowColor: '#00000030',
			shadowOpacity: 0.5,
			boxShadow:
				'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
			transition: 'transform 0.2s ease-out',
			'&:hover': {
				transform: 'scale(1.05)',
			},
		},
		media: {
			paddingTop: '56.25%', // 16:9 aspect ratio
			position: 'relative',
		},
		playerWrapper: {
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%',
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		footer: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
		button: {
			color: 'white',
			borderRadius: 20,
			alignSelf: 'center',
			fontWeight: 'bold',
		},
	}));

	const classes = useStyles();
	const history = useHistory();

	const onDelete = async () => {
		await firebase.database().ref(`videos/${id}`).remove();
	};

	return (
		<Card className={classes.root}>
			<ReactPlayer url={videoUrl} width='100%' height='auto' controls />
			<CardContent>
				<Typography gutterBottom variant='h6' component='h2'>
					{title}
				</Typography>
				<Typography
					variant='body2'
					color='textSecondary'
					component='p'
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{description}
				</Typography>
			</CardContent>
			<CardActions className={classes.footer}>
				<Button
					variant='contained'
					size='small'
					color='primary'
					className={classes.button}
					style={{ backgroundColor: '#88B12E' }}
					onClick={() => history.push('/admins/update-video/' + id)}
					endIcon={<EditIcon />}
				>
					Edit
				</Button>
				<Button
					variant='contained'
					size='small'
					className={classes.button}
					style={{ backgroundColor: '#d89447' }}
					onClick={onDelete}
					endIcon={<DeleteForever />}
				>
					Delete
				</Button>
			</CardActions>
		</Card>
	);
};

MediaCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	videoUrl: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default MediaCard;
