import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as Navigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PanoramaIcon from '@material-ui/icons/Panorama';
import ListIcon from '@material-ui/icons/List';
import sharedStyles from '../shared/styles';
import firebase from 'firebase/app';
import 'firebase/database';
import { CircularProgress, Grid } from '@material-ui/core';
import VideosContainer from './VideoSection/VideosContainer';
import groupItemsByCategory from '../../helpers/groupItemsByCategory';
import CategoriesModal from './VideoSection/CategoriesModal';
import BannerModal from './VideoSection/BannerModal';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
		justifyContent: 'space-between',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'start',
		},
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
		alignSelf: 'center',
		fontWeight: 'bold',
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 230,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	table: {
		width: '30%',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	button_container: {
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			paddingTop: 20,
		},
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 20,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
		},
	},
}));

const VideoSection = () => {
	const sharedClasses = sharedStyles();
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [showBanner, setShowBanner] = useState(false);

	const handleModalOpen = (type = 'faculties') => {
		console.log(type);
		if (type === 'faculties') {
			setModalOpen(true);
		} else {
			setShowBanner(true);
		}
	};

	const handleModalClose = (type = 'faculties') => {
		if (type === 'faculties') {
			setModalOpen(false);
		} else {
			setShowBanner(false);
		}
	};

	useEffect(() => {
		retrieveVideos();
	}, []);

	const retrieveVideos = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('videos')
				.on('value', async (snapshot) => {
					const videoArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);

					videoArray.sort((a, b) => b.timestamp - a.timestamp);
					const categories = groupItemsByCategory(videoArray);
					setData(categories);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.mainText}>
					Video repository Section
				</Typography>
				<div className={classes.addNewDiv}>
					<div style={{ display: 'flex' }}>
						<Typography className={classes.text}>
							Click here to add a new video
						</Typography>
						<Navigate to='/admins/add-video' style={{ textDecoration: 'none' }}>
							{loading ? (
								<CircularProgress style={{ marginLeft: 20 }} />
							) : (
								<Button
									variant='contained'
									color='primary'
									className={classes.button}
									endIcon={<AddIcon />}
								>
									Add
								</Button>
							)}
						</Navigate>
					</div>
					<div className={classes.button_container}>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							endIcon={<PanoramaIcon />}
							onClick={() => handleModalOpen('banner')}
						>
							Banner
						</Button>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							endIcon={<ListIcon />}
							onClick={() => handleModalOpen('faculties')}
						>
							Faculties
						</Button>
						<CategoriesModal open={modalOpen} onClose={handleModalClose} />
						<BannerModal open={showBanner} onClose={handleModalClose} />
					</div>
				</div>
			</div>
			<div className={classes.body}>
				<Grid style={{ width: '100%' }}>
					{Object.keys(data).map((category) => (
						<VideosContainer
							key={category}
							videos={data[category]}
							category={category}
							loading={loading}
						/>
					))}
				</Grid>
			</div>
		</div>
	);
};

export default VideoSection;
