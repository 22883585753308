import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import firebase from 'firebase/app';
import 'firebase/database';
import { useAppContext } from '../../../data/AppContext';
import ApprovedOrders from './ApprovedOrders';
import PendingOrders from './PendingOrders';
import DeclinedOrders from './DeclinedOrders';

const customStyles = makeStyles((theme) => ({
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 10,
		fontSize: 10,
	},
	root: {
		alignSelf: 'center',
		alignItems: 'center',
		padding: '5%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: 0,
		},
	},
	tabs: {
		height: 60,
		borderRadius: 0,
		display: 'flex',
		alignSelf: 'center',
		alignItems: 'center',
		width: '100%',
		backgroundColor: 'white',
		shadowColor: '#00000030',
		shadowOpacity: 0.3,
		boxShadow:
			'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
		[theme.breakpoints.down('sm')]: {
			height: 40,
		},
	},
	tab: {
		height: '100%',
		flex: 1,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tabText: {
		fontSize: '18px',
		color: '#1A1A1A',
		fontFamily: 'Poppins',
		fontWeight: '300',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
	dots: {
		fontSize: 17,
		color: 'blue',
		marginRight: 10,
		[theme.breakpoints.down('sm')]: {
			marginRight: 3,
		},
	},
}));

const Orders = () => {
	const classes = customStyles();

	const [selectedTab, setSelectedTab] = useState(0);

	const user = useAppContext();

	const [data, setData] = useState([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		retrieveOrders();
	}, [user]);

	const filterUserOrders = async (products) => {
		const filteredProducts = await products.filter(
			(item) => item?.seller?.uid === user?.uid
		);

		return filteredProducts;
	};

	const retrieveOrders = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('orders')
				.on('value', async (snapshot) => {
					const productsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);
					productsArray.sort((a, b) => a.timestamp - b.timestamp);

					const filteredData = await filterUserOrders(productsArray);

					setData(filteredData);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.productTabs}></div>
			<div className={classes.tabs}>
				<div
					className={classes.tab}
					style={{ backgroundColor: selectedTab === 0 && '#93BC38' }}
					onClick={() => setSelectedTab(0)}
				>
					<FiberManualRecordIcon
						classes={{ root: classes.dots }}
						style={{ color: '#FFE200' }}
					/>
					<Typography
						className={classes.tabText}
						style={{
							color: selectedTab === 0 && 'white',
							fontWeight: selectedTab === 0 && '500',
						}}
					>
						Pending
					</Typography>
				</div>
				<div
					className={classes.tab}
					style={{ backgroundColor: selectedTab === 1 && '#93BC38' }}
					onClick={() => setSelectedTab(1)}
				>
					<FiberManualRecordIcon
						classes={{ root: classes.dots }}
						style={{ color: '#14FD00' }}
					/>
					<Typography
						className={classes.tabText}
						style={{
							color: selectedTab === 1 && 'white',
							fontWeight: selectedTab === 1 && '500',
						}}
					>
						Approved
					</Typography>
				</div>
				<div
					className={classes.tab}
					style={{ backgroundColor: selectedTab === 2 && '#93BC38' }}
					onClick={() => setSelectedTab(2)}
				>
					<FiberManualRecordIcon
						classes={{ root: classes.dots }}
						style={{ color: '#FF5800' }}
					/>
					<Typography
						className={classes.tabText}
						style={{
							color: selectedTab === 2 && 'white',
							fontWeight: selectedTab === 2 && '500',
						}}
					>
						Declined
					</Typography>
				</div>
			</div>
			{selectedTab === 0 && <PendingOrders data={data} loading={loading} />}
			{selectedTab === 1 && <ApprovedOrders data={data} loading={loading} />}
			{selectedTab === 2 && <DeclinedOrders data={data} loading={loading} />}
		</div>
	);
};

export default Orders;
