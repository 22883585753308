function groupItemsByCategory(items) {
	const result = {};
	for (let i = 0; i < items.length; i++) {
		const category = items[i].category;
		if (!result[category]) {
			result[category] = [];
		}
		result[category].push(items[i]);
	}
	// sort items by timestamp within each category
	for (const category in result) {
		result[category].sort((a, b) => a.timestamp - b.timestamp);
	}
	return result;
}

export default groupItemsByCategory;
