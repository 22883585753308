import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	containerStyle: {
		backgroundColor: 'white',
		padding: theme.spacing(2),
		borderRadius: 10,
		width: '100%',
		shadowColor: '#00000030',
		shadowOpacity: 0.5,
		boxShadow:
			'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
	},
	ordersCardStyle: {
		backgroundColor: 'white',
		borderRadius: 10,
		paddingTop: theme.spacing(1),
		width: '100%',
		shadowColor: '#00000030',
		shadowOpacity: 0.5,
		boxShadow:
			'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
	},
}));

export default useStyles;
