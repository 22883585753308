import Home from '../Components/Home';
import Login from '../Components/Authentications/Login';
import Signup from '../Components/Authentications/Signup';
import ResetPassword from '../Components/Authentications/ResetPassword';
import Pending from '../Components/Authentications/UnderReview';
import ResetPasswordEmail from '../Components/Authentications/ResetPasswordEmail';
import AccountCreated from '../Components/Authentications/AccountCreated';
import Products from '../Components/Home/Products';
import AddProduct from '../Components/Home/Products/AddProduct';
import AuthNav from '../Components/AuthNav';
import AuthAdmin from '../Components/AuthNav/AdminAuth';
import Account from '../Components/Home/Account';
import Notifications from '../Components/Home/Notifications';
import Orders from '../Components/Home/Orders';
import AdminProducts from '../Components/Admins/Products';
import AdminsOrders from '../Components/Admins/Orders';
import AdminsNotifications from '../Components/Admins/Notifications';
import AdminsAccount from '../Components/Admins/Account';
import AdminsHome from '../Components/Admins';
import Veterinaries from '../Components/Admins/Veterinaries';
import Suppliers from '../Components/Admins/Suppliers';
import Quiz from '../Components/Admins/Quiz';
import AddQuiz from '../Components/Admins/Quiz/AddQuiz';
import VideoSection from '../Components/Admins/VideoSection';
import UploadForm from '../Components/Admins/Quiz/UploadForm';
import SubscriptionTable from '../Components/shared/Tables/SubscriptionTable';

const routes = [
	{
		exact: true,
		path: '/',
		component: AuthNav,
	},
	{
		exact: true,
		path: '/admins',
		component: AuthAdmin,
	},
	{
		path: '/login',
		component: Login,
	},
	{
		path: '/signup',
		component: Signup,
	},
	{
		path: '/reset-password',
		component: ResetPassword,
	},
	{
		path: '/reset-password-confirm',
		component: ResetPasswordEmail,
	},
	{
		path: '/account-created',
		component: AccountCreated,
	},
	{
		path: '/pending',
		component: Pending,
	},
	{
		path: '/admins',
		component: AdminsHome,
		routes: [
			{
				path: '/admins/home',
				component: AdminProducts,
			},
			{
				path: '/admins/products',
				component: AdminProducts,
			},
			{
				path: '/admins/orders',
				component: AdminsOrders,
			},
			{
				path: '/admins/account',
				component: AdminsAccount,
			},
			{
				path: '/admins/vet',
				component: Veterinaries,
			},
			{
				path: '/admins/suppliers',
				component: Suppliers,
			},
			{
				path: '/admins/quiz',
				component: Quiz,
			},

			{
				path: '/admins/videos',
				component: VideoSection,
			},
			{
				path: '/admins/add-video',
				component: UploadForm,
			},
			{
				path: '/admins/update-video/:id',
				component: UploadForm,
			},
			{
				path: '/admins/subscription',
				component: SubscriptionTable,
			},
			{
				path: '/admins/add-quiz',
				component: AddQuiz,
			},
			{
				path: '/admins/update-quiz/:id',
				component: AddQuiz,
			},
			{
				path: '/admins/notifications',
				component: AdminsNotifications,
			},
		],
	},
	{
		path: '/',
		component: Home,
		routes: [
			{
				path: '/home',
				component: Products,
			},
			{
				path: '/products',
				component: Products,
			},
			{
				path: '/add-product',
				component: AddProduct,
			},
			{
				path: '/orders',
				component: Orders,
			},
			{
				path: '/account',
				component: Account,
			},
			{
				path: '/notifications',
				component: Notifications,
			},
		],
	},
];

export default routes;
