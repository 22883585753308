import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(3),
		alignItems: 'center',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 20,
		width: 173,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
	cancel: {
		margin: theme.spacing(3, 1, 2),
		flex: 1,
		borderRadius: 20,
		width: 150,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
	fullSubmit: {
		margin: theme.spacing(3, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 20,
	},
	logo: {
		margin: theme.spacing(2),
		width: 100,
		height: 100,
	},
	created: {
		marginBottom: theme.spacing(2),
		width: 200,
		height: 200,
	},
	registered: {
		display: 'flex',
	},
	optionsText: {
		textAlign: 'center',
		marginTop: 15,
		color: '#88B12E',
		fontWeight: 'bold',
	},
	link: { textDecoration: 'none' },
	addProductWrapper: {
		width: '100%',
		paddingRight: '15%',
		paddingLeft: '15%',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
			paddingLeft: 0,
		},
	},
	title: {
		textAlign: 'center',
		fontSize: '25px',
		marginTop: '24px',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		fontWeight: '500',
	},
}));

export default useStyles;
