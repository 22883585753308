/* eslint-disable react/prop-types */
import { createContext, useState, useContext } from 'react';

const AppContext = createContext();

const UpdateAppContext = createContext();

export const useAppContext = () => useContext(AppContext);
export const useUpdateContext = () => useContext(UpdateAppContext);

export const ContextProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState({});

	const updateUser = (user) => setCurrentUser(user);

	return (
		<AppContext.Provider value={currentUser}>
			<UpdateAppContext.Provider value={updateUser}>
				{children}
			</UpdateAppContext.Provider>
		</AppContext.Provider>
	);
};
