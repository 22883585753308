import React, { useState } from 'react';
import { Link as Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './useStyles';
import AuthTextField from './AuthTextField';
import { useHistory } from 'react-router-dom';

function Copyright() {
	return (
		<Typography
			variant='body2'
			color='textSecondary'
			align='center'
			style={{ color: '#FFFFFF' }}
		>
			{'Copyright © '}
			<Link color='inherit' href='http://market.mychui.com/'>
				Chui
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function Login() {
	const classes = useStyles(),
		[submitting, setSubmitting] = useState(false),
		[authError, setAuthError] = useState(),
		logo =
			'https://res.cloudinary.com/djqhkyffp/image/upload/v1629826406/mobivet/inside_1_oyrgrh.png';

	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Required'),
		}),
		onSubmit: async (values) => {
			setAuthError();
			setSubmitting(true);
			const myURL = { url: 'http://market.mychui.com/login' };
			await firebase
				.auth()
				.sendPasswordResetEmail(values.email, myURL)
				.then(() => {
					history.push('reset-password-confirm');
					setSubmitting(false);
				})
				.catch((error) => {
					console.log(error);
					setAuthError(error.message.split('.')?.[0]);
					setSubmitting(true);
				});

			// Sign Out the user.
			firebase.auth().signOut();
			setSubmitting(false);
		},
	});

	return (
		<div className={classes.wrapper} style={{ height: '100vh' }}>
			<Container component='main' maxWidth='xs' className={classes.container}>
				<div className={classes.paper}>
					<img src={logo} alt='chui logo' className={classes.logo} />
					<form className={classes.form} onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									required
									fullWidth
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									error={formik.touched.email && formik.errors.email}
									helperText={
										formik.touched.email && formik.errors.email
											? formik.errors.email
											: null
									}
									{...formik.getFieldProps('email')}
								/>
							</Grid>
						</Grid>
						<Typography
							style={{
								textAlign: 'center',
								color: '#ff0000',
								fontSize: 14,
								marginTop: 10,
							}}
						>
							{authError && authError}
						</Typography>
						<Button
							onClick={() => null}
							fullWidth
							loading={submitting.toString()}
							disabled={submitting}
							variant='contained'
							className={classes.fullSubmit}
							type='submit'
						>
							{!submitting ? (
								'Recover Password'
							) : (
								<CircularProgress color='primary' size={20} />
							)}
						</Button>
						<div className={classes.registered}>
							<Typography style={{ textAlign: 'center' }} color='textSecondary'>
								Go back to&nbsp;
							</Typography>
							<Navigate to='/login' className={classes.link}>
								<Typography
									style={{ marginTop: 0 }}
									className={classes.optionsText}
								>
									Login
								</Typography>
							</Navigate>
						</div>
					</form>
				</div>
			</Container>
			<Box>
				<Copyright />
			</Box>
		</div>
	);
}
