import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import sharedStyles from '../../shared/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 340,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 20,
			flexDirection: 'column',
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'row',
			justifyContent: 'center',
		},
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 55,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
			marginLeft: 10,
		},
	},
	status: {
		display: 'flex',
		alignItems: 'center',
	},
	dot: {
		marginRight: 5,
		fontSize: 19,
	},
	notificationCard: {
		width: 600,
		display: 'flex',
		height: 100,
		marginBottom: 20,
		flexDirection: 'column',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 20,
			height: 100,
			flexDirection: 'column',
		},
	},
	headers: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-start',
		alignContent: 'center',
	},
	notIcon: {
		color: '#449089',
		fontSize: '19px',
		fontFamily: 'Poppins',
		textAlign: 'center',
		marginTop: 3,
	},
	headerText: {
		color: '#516A18',
		fontSize: '18px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		marginLeft: 10,
	},
	date: {
		color: '#7CB057',
		fontFamily: 'Poppins',
		fontWeight: '300',
		fontSize: '9px',
		textAlign: 'center',
		marginLeft: 10,
		marginTop: 10,
	},
	cancel: {
		position: 'relative',
		right: 10,
		zIndex: 2,
	},
}));

const Products = () => {
	const sharedClasses = sharedStyles();
	const classes = useStyles();
	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={classes.body}>
				<div
					className={`${sharedClasses.containerStyle} ${classes.productsCounter}`}
				>
					<div>
						<div className={classes.status}>
							<FiberManualRecordIcon
								className={classes.dot}
								style={{ color: '#FFE200' }}
							/>
							<Typography className={classes.text}>Pending (1)</Typography>
						</div>
						<div className={classes.status}>
							<FiberManualRecordIcon className={classes.dot} />
							<Typography className={classes.text}>Approved (2)</Typography>
						</div>
						<div className={classes.status}>
							<FiberManualRecordIcon
								className={classes.dot}
								style={{ color: '#FF5800' }}
							/>
							<Typography className={classes.text}>Declined (1)</Typography>
						</div>
					</div>
					<div className={classes.counter}>
						<Typography className={classes.text}>Total Orders</Typography>
						<Typography className={classes.counterText}>5</Typography>
					</div>
				</div>
				<div>
					{Array.from([1, 2, 3, 4], (item) => (
						<div
							className={`${sharedClasses.containerStyle} ${classes.notificationCard}`}
							key={item}
						>
							{/* <CancelIcon className={classes.cancel} /> */}
							<div className={classes.headers}>
								<NotificationsIcon className={classes.notIcon} />
								<Typography className={classes.headerText}>Activity</Typography>
								<Typography className={classes.date}>2021-01-04</Typography>
							</div>
							<Typography className={classes.text}>
								You have 10 pending orders
							</Typography>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Products;
