import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import firebase from 'firebase/app';
import firebaseConfig from './config/firebaseConfig';
import Nav from './Navigation';
import { ContextProvider } from './data/AppContext';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#88B12E',
		},
		secondary: {
			main: '#516A18',
		},
		text: {
			primary: '#88B12E',
		},
	},
});

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const App = () => {
	console.log = console.warn = console.error = () => {};
	return (
		<ThemeProvider theme={theme}>
			<ContextProvider>
				<Nav />
			</ContextProvider>
		</ThemeProvider>
	);
};
export default App;
