import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/database';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import { useAppContext } from '../../../data/AppContext';

const customStyles = makeStyles(() => ({
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 10,
		fontSize: 10,
	},
}));

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => (
		<Button
			variant='outlined'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
			style={{ color: '#93BC38' }}
		>
			Delete
		</Button>
	)),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => (
		<Button
			variant='contained'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
		>
			Edit
		</Button>
	)),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Table = () => {
	const [data, setData] = useState([]); //table data

	//for error handling
	const [errorMessages, setErrorMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	const user = useAppContext();

	useEffect(() => {
		retrieveProducts();
	}, [user]);

	const retrieveProducts = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('products')
				.on('value', async (snapshot) => {
					const productsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);

					setData(productsArray);
					setErrorMessages([]);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const { width } = useWindowDimensions();

	const tableStyles = makeStyles((theme) => ({
		root: {
			width: 750,
			[theme.breakpoints.down('sm')]: {
				width: (width * 85) / 100,
			},
		},
	}));
	const classes = tableStyles();

	return (
		<div className={classes.root}>
			<div>
				{errorMessages.length > 0 && (
					<Alert severity='error'>
						{errorMessages.map((msg, i) => (
							<div key={i}>{msg}</div>
						))}
					</Alert>
				)}
			</div>
			<MaterialTable
				title='List of products'
				columns={[
					{ title: 'Name', field: 'name' },
					{ title: 'Number of Units', field: 'units', type: 'numeric' },
					{ title: 'Unit Price (TZS)', field: 'price', type: 'numeric' },
					{ title: 'Seller', field: 'seller.store' },
					{ title: 'Telephone', field: 'seller.phone' },
				]}
				data={data}
				options={{
					actionsColumnIndex: -1,
					headerStyle: { color: '#1A1A1A' },
					rowStyle: { color: '#1A1A1A' },
				}}
				icons={tableIcons}
				isLoading={loading}
			/>
		</div>
	);
};

export default Table;
