import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './useStyles';
import accountCreated from '../../assets/images/undraw_Mail_sent_re_0ofv.png';

function Copyright() {
	return (
		<Typography
			variant='body2'
			color='textSecondary'
			align='center'
			style={{ color: '#FFFFFF' }}
		>
			{'Copyright © '}
			<Link color='inherit' href='http://market.mychui.com/'>
				Chui
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function Login() {
	const classes = useStyles(),
		logo =
			'https://res.cloudinary.com/djqhkyffp/image/upload/v1629826406/mobivet/inside_1_oyrgrh.png';

	return (
		<div className={classes.wrapper} style={{ height: '100vh' }}>
			<Container component='main' maxWidth='xs' className={classes.container}>
				<div className={classes.paper}>
					<img src={logo} alt='chui logo' className={classes.logo} />
					<Typography
						style={{ marginTop: 0, fontSize: 18 }}
						className={classes.optionsText}
					>
						Email sent successfully!!
					</Typography>
					<img
						src={accountCreated}
						alt='Acount Created'
						className={classes.created}
					/>
					<Typography
						style={{ marginTop: 0, fontSize: 16 }}
						className={classes.optionsText}
					>
						Please check your email to reset your password.
					</Typography>
				</div>
			</Container>
			<Box>
				<Copyright />
			</Box>
		</div>
	);
}
