import React, { useState } from 'react';
import { Link as Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './useStyles';
import AuthTextField from './AuthTextField';
import PasswordTextField from './PasswordTextField';
import { useHistory } from 'react-router-dom';

function Copyright() {
	return (
		<Typography
			variant='body2'
			color='textSecondary'
			align='center'
			style={{ color: '#FFFFFF' }}
		>
			{'Copyright © '}
			<Link color='inherit' href='http://market.mychui.com/'>
				Chui
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function Signup() {
	const classes = useStyles(),
		[submitting, setSubmitting] = useState(false),
		[authError, setAuthError] = useState(),
		logo =
			'https://res.cloudinary.com/djqhkyffp/image/upload/v1629826406/mobivet/inside_1_oyrgrh.png';

	const phoneRegExp = /^\d{10}$/;
	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			email: '',
			store: '',
			phone: '',
			country: '',
			city: '',
			password: '',
			newPassword: '',
			type: 'supplier',
			isVerified: false,
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			store: Yup.string().required('Required'),
			country: Yup.string()
				.min(3, 'Must be 3 characters or more')
				.required('Required'),
			city: Yup.string()
				.min(3, 'Must be 3 characters or more')
				.required('Required'),
			phone: Yup.string()
				.required('Required')
				.matches(phoneRegExp, 'Phone number is not valid'),
			password: Yup.string().required('Password is required'),
			newPassword: Yup.string()
				.required('Required')
				.oneOf([Yup.ref('password'), null], 'Passwords must match'),
		}),
		onSubmit: async (values) => {
			setAuthError();
			setSubmitting(true);
			await firebase
				.auth()
				.createUserWithEmailAndPassword(values.email, values.password)
				.then(async (result) => {
					const payload = { ...values, uid: result?.user?.uid };
					delete payload.password;
					delete payload.newPassword;
					await firebase
						.database()
						.ref(`market_users/${result?.user?.uid}`)
						.set(payload);
					const myURL = { url: 'http://market.mychui.com/login' };

					result.user
						.sendEmailVerification(myURL)
						.then(() => {
							history.push('account-created');
						})
						.catch((error) => {
							console.log(error);
						});

					// Sign Out the user.
					firebase.auth().signOut();
					setSubmitting(false);
				})
				.catch((error) => {
					// Update the error
					if (error.code === 'auth/email-already-in-use') {
						console.log(error.message);
						setAuthError(error.message);
					} else {
						setAuthError(error.message);
					}
					setSubmitting(false);
				});
		},
	});

	return (
		<div className={classes.wrapper}>
			<Container component='main' maxWidth='xs' className={classes.container}>
				<div className={classes.paper}>
					<img src={logo} alt='chui logo' className={classes.logo} />
					<form className={classes.form} onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									fullWidth
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									error={formik.touched.email && formik.errors.email}
									helperText={
										formik.touched.email && formik.errors.email
											? formik.errors.email
											: null
									}
									{...formik.getFieldProps('email')}
								/>
							</Grid>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									fullWidth
									id='store'
									label='Store name'
									name='store'
									error={formik.touched.store && formik.errors.store}
									helperText={
										formik.touched.store && formik.errors.store
											? formik.errors.store
											: null
									}
									{...formik.getFieldProps('store')}
								/>
							</Grid>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									fullWidth
									id='phone'
									label='Phone number'
									type='tel'
									name='phone'
									error={formik.touched.phone && formik.errors.phone}
									helperText={
										formik.touched.phone && formik.errors.phone
											? formik.errors.phone
											: null
									}
									{...formik.getFieldProps('phone')}
								/>
							</Grid>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									fullWidth
									name='country'
									label='Country'
									type='text'
									id='country'
									error={formik.touched.country && formik.errors.country}
									helperText={
										formik.touched.country && formik.errors.country
											? formik.errors.country
											: null
									}
									{...formik.getFieldProps('country')}
								/>
							</Grid>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									fullWidth
									id='city'
									label='City'
									type='text'
									name='city'
									error={formik.touched.city && formik.errors.city}
									helperText={
										formik.touched.city && formik.errors.city
											? formik.errors.city
											: null
									}
									{...formik.getFieldProps('city')}
								/>
							</Grid>
							<Grid item xs={12}>
								<PasswordTextField
									variant='outlined'
									fullWidth
									name='password'
									label='Password'
									type='password'
									id='password'
									error={formik.touched.password && formik.errors.password}
									helperText={
										formik.touched.password && formik.errors.password
											? formik.errors.password
											: null
									}
									{...formik.getFieldProps('password')}
								/>
							</Grid>
							<Grid item xs={12}>
								<PasswordTextField
									variant='outlined'
									fullWidth
									name='newPassword'
									label='Repeat Password'
									type='password'
									id='newPassword'
									error={
										formik.touched.newPassword && formik.errors.newPassword
									}
									helperText={
										formik.touched.newPassword && formik.errors.newPassword
											? formik.errors.newPassword
											: null
									}
									{...formik.getFieldProps('newPassword')}
								/>
							</Grid>
						</Grid>
						<Typography
							style={{
								textAlign: 'center',
								color: '#ff0000',
								fontSize: 14,
								marginTop: 10,
							}}
						>
							{authError && authError}
						</Typography>
						<Button
							onClick={() => null}
							fullWidth
							loading={submitting.toString()}
							disabled={submitting}
							variant='contained'
							className={classes.submit}
							type='submit'
						>
							{!submitting ? (
								'Sign up'
							) : (
								<CircularProgress color='primary' size={20} />
							)}
						</Button>
						<div className={classes.registered}>
							<Typography style={{ textAlign: 'center' }} color='textSecondary'>
								Already a member?&nbsp;
							</Typography>
							<Navigate to='/login' className={classes.link}>
								<Typography
									style={{ marginTop: 0 }}
									className={classes.optionsText}
								>
									Login!
								</Typography>
							</Navigate>
						</div>
					</form>
				</div>
			</Container>
			<Box>
				<Copyright />
			</Box>
		</div>
	);
}
