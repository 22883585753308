import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const AuthTextField = withStyles({
	root: {
		'& .MuiOutlinedInput-root': {
			borderRadius: `19px`,
		},
		input: { height: '40px' },
	},
})(TextField);

export default AuthTextField;
