import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import sharedStyles from '../shared/styles';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ProductTable from '../../Components/shared/Tables/AdminProductTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app';
import 'firebase/database';
import { useAppContext } from '../../data/AppContext';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 230,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	table: {
		width: '30%',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 55,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
		},
	},
}));

const Products = () => {
	const sharedClasses = sharedStyles();
	const classes = useStyles();

	const user = useAppContext();

	const [data, setData] = useState([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		retrieveProducts();
	}, [user]);

	const retrieveProducts = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('products')
				.on('value', async (snapshot) => {
					const productsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);

					setData(productsArray);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.mainText}>Products Section</Typography>
			</div>
			<div className={classes.body}>
				<div
					className={`${sharedClasses.containerStyle} ${classes.productsCounter}`}
				>
					<ShoppingBasketIcon className={classes.counterIcon} />
					<div className={classes.counter}>
						<Typography className={classes.text}>Products</Typography>
						{loading ? (
							<CircularProgress />
						) : (
							<Typography className={classes.counterText}>
								{data.length || 0}
							</Typography>
						)}
					</div>
				</div>

				<ProductTable />
			</div>
		</div>
	);
};

export default Products;
