/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import fetchAPI from '../../../helpers/fetchApi';

const customStyles = makeStyles(() => ({
	button: {
		marginLeft: 20,
		color: 'white',
		width: 100,
		borderRadius: 10,
		fontSize: 10,
		fontWeight: 'bold',
	},
}));

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => (
		<Button
			variant='outlined'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
			style={{ color: '#93BC38' }}
		>
			Delete
		</Button>
	)),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => (
		<Button
			variant='contained'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
		>
			Edit
		</Button>
	)),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Veterinaries = () => {
	const [data, setData] = useState([]);

	const history = useHistory();

	//for error handling
	const [errorMessages, setErrorMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		retrieveSuppliers();
	}, []);

	const retrieveSuppliers = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('market_users')
				.on('value', async (snapshot) => {
					const suppliersArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);

					const filteredArray = suppliersArray.filter(
						(item) => item?.type === 'supplier'
					);

					setData(filteredArray);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const handleActivateUser = async (raw, resolve) => {
		setLoading(true);
		await firebase
			.database()
			.ref(`market_users/${raw.uid}`)
			.update({ isVerified: !raw?.isVerified })
			.then(async () => {
				setLoading(false);
				resolve();
				if (!raw?.isVerified) {
					await fetchAPI('/market/approve/', {
						method: 'POST',
						body: { email: raw?.email, name: raw?.store },
					});
				}
			})
			.catch((err) => {
				setErrorMessages(['Update failed! Server error']);
				console.log(err);
				setLoading(false);
				resolve();
			});
	};

	const renderActivateIcon = (status) =>
		status
			? forwardRef((props, ref) => (
					<Button
						variant='outlined'
						color='primary'
						component='span'
						{...props}
						ref={ref}
						className={customStyles().button}
						style={{
							color: '#FFF',
							backgroundColor: '#D11A2A',
							borderColor: '#D11A2A',
						}}
					>
						Deactivate
					</Button>
			  ))
			: forwardRef((props, ref) => (
					<Button
						variant='contained'
						color='primary'
						component='span'
						{...props}
						ref={ref}
						className={customStyles().button}
					>
						Activate
					</Button>
			  ));

	const { width } = useWindowDimensions();

	const tableStyles = makeStyles((theme) => ({
		root: {
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				width: (width * 85) / 100,
			},
		},
	}));
	const classes = tableStyles();

	return (
		<div className={classes.root}>
			<div>
				{errorMessages.length > 0 && (
					<Alert severity='error'>
						{errorMessages.map((msg, i) => (
							<div key={i}>{msg}</div>
						))}
					</Alert>
				)}
			</div>
			<MaterialTable
				title='List of Suppliers'
				columns={[
					{ title: 'Store', field: 'store' },
					{ title: 'City', field: 'city' },
					{ title: 'Telephone', field: 'phone' },
					{ title: 'Email', field: 'email' },
					{ title: 'Activated', field: 'isVerified' },
				]}
				data={data}
				options={{
					actionsColumnIndex: -1,
					headerStyle: { color: '#1A1A1A' },
					rowStyle: { color: '#1A1A1A' },
				}}
				icons={tableIcons}
				actions={[
					(rowData) => ({
						icon: renderActivateIcon(rowData.isVerified),
						onClick: () =>
							new Promise((resolve) => {
								handleActivateUser(rowData, resolve);
							}),
					}),
				]}
				isLoading={loading}
			/>
		</div>
	);
};

export default Veterinaries;
