/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/database';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import { useAppContext } from '../../../data/AppContext';
import moment from 'moment';

const customStyles = makeStyles(() => ({
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 10,
		fontSize: 10,
	},
}));

const styles = {
	Reject: { backgroundColor: '#c33' },
	Deactivate: { backgroundColor: '#c33' },
	Activate: { backgroundColor: '#3c3' },
	Reactivate: { backgroundColor: '#3c3' },
};

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => (
		<Button
			variant='outlined'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
			style={{ color: '#93BC38' }}
		>
			Delete
		</Button>
	)),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => (
		<Button
			variant='contained'
			color='primary'
			component='span'
			{...props}
			ref={ref}
			className={customStyles().button}
		>
			Edit
		</Button>
	)),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const SubscriptionTable = () => {
	const [data, setData] = useState([]); //table data

	const [errorMessages, setErrorMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	const user = useAppContext();

	useEffect(() => {
		retrieveSubscription();
	}, [user]);

	const retrieveSubscription = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('subscriptions')
				.on('value', async (snapshot) => {
					const currentDate = new Date();
					const productsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => {
									const validToDate = e[1]?.endDate
										? new Date(e[1]?.endDate)
										: null;
									return {
										...e[1],
										endDate: validToDate
											? moment(validToDate).format('LL')
											: '',
										uid: e[0],
										status:
											e[1]?.status === 'active' &&
											validToDate &&
											validToDate < currentDate
												? 'expired'
												: e[1]?.status,
									};
							  })
							: [];
					setLoading(false);
					setData(productsArray);
					setErrorMessages([]);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const handleSubsStatus = async (props) => {
		setLoading(true);
		const { rowData, type } = props;
		let payload;
		const endDate = new Date();

		if (type === 'Activate' || type === 'Reactivate') {
			endDate.setDate(endDate.getDate() + 30);
			payload = {
				status: 'active',
				endDate,
			};
		}

		if (type === 'Reject' || type === 'Deactivate') {
			payload = {
				status: 'rejected',
				endDate,
			};
		}
		await firebase
			.database()
			.ref(`subscriptions/${rowData.uid}`)
			.update(payload)
			.then(async () => {
				setLoading(false);
			})
			.catch((err) => {
				setErrorMessages(['Update failed! Server error']);
				console.log(err);
				setLoading(false);
			});
	};

	const { width } = useWindowDimensions();

	const tableStyles = makeStyles((theme) => ({
		root: {
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				width: (width * 85) / 100,
			},
		},
	}));
	const classes = tableStyles();

	const generateButtons = () => {
		const buttons = [];
		const renderButton = (label, onClick, disabled = false) => ({
			icon: forwardRef((props, ref) => (
				<Button
					variant='contained'
					color='primary'
					component='span'
					{...props}
					ref={ref}
					className={customStyles().button}
					style={!disabled ? styles[label] : {}}
					disabled={disabled}
				>
					{label}
				</Button>
			)),
			onClick,
		});

		buttons.push((rowData) => {
			if (rowData.status === 'pending') {
				return renderButton('Activate', () =>
					handleSubsStatus({ rowData, type: 'Activate' })
				);
			}
		});

		buttons.push((rowData) => {
			if (rowData.status === 'pending') {
				return renderButton('Reject', () =>
					handleSubsStatus({ rowData, type: 'Reject' })
				);
			}
		});

		buttons.push((rowData) => {
			if (rowData.status === 'expired') {
				return renderButton('Reactivate', () =>
					handleSubsStatus({ rowData, type: 'Reactivate' })
				);
			}
		});

		buttons.push((rowData) => {
			if (rowData.status === 'active') {
				const endDate = new Date(rowData.endDate);
				const today = new Date();
				if (endDate < today) {
					return renderButton('Reactivate', () =>
						handleSubsStatus({ rowData, type: 'Reactivate' })
					);
				} else {
					return renderButton('Deactivate', () =>
						handleSubsStatus({ rowData, type: 'Deactivate' })
					);
				}
			}
		});

		buttons.push((rowData) => {
			if (rowData.status === 'rejected') {
				return renderButton('Activate', () =>
					handleSubsStatus({ rowData, type: 'Activate' })
				);
			}
		});
		buttons.push((rowData) => {
			if (rowData.status === 'rejected') {
				return renderButton(
					'Deactivate',
					() => handleSubsStatus({ rowData, type: 'Activate' }),
					true
				);
			}
		});
		return buttons;
	};

	return (
		<div className={classes.root}>
			<div>
				{errorMessages.length > 0 && (
					<Alert severity='error'>
						{errorMessages.map((msg, i) => (
							<div key={i}>{msg}</div>
						))}
					</Alert>
				)}
			</div>
			<MaterialTable
				title='List of Subscribers'
				columns={[
					{ title: 'Name', field: 'names' },
					{ title: 'Telephone', field: 'phone' },
					{ title: 'Email', field: 'email' },
					{ title: 'Expiration Date', field: 'endDate' },
					{ title: 'Status', field: 'status' },
				]}
				data={data}
				options={{
					actionsColumnIndex: -1,
					headerStyle: { color: '#1A1A1A' },
					rowStyle: { color: '#1A1A1A' },
				}}
				actions={[...generateButtons()]}
				icons={tableIcons}
				isLoading={loading}
			/>
		</div>
	);
};

export default SubscriptionTable;
