/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Link as Navigate, useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import TextField from './TextField';
import sharedStyles from '../../shared/styles';
import uploadImages from '../../../helpers/upload';
import getBase64 from '../../../helpers/getBase64';
import { useAppContext } from '../../../data/AppContext';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
} from '@material-ui/core';

export default function AddProduct() {
	const classes = useStyles(),
		[submitting, setSubmitting] = useState(false),
		[createError, setCreateError] = useState();

	const [loading, setLoading] = useState(true);
	const [initialValues, setInitialValues] = useState({
		question: '',
		optionA: '',
		optionB: '',
		optionC: '',
		optionD: '',
		answer: '',
	});

	const user = useAppContext();

	const { id } = useParams();

	const [images, setImages] = useState();
	const [state, setState] = useState({
		optionA: false,
		optionB: false,
		optionC: false,
		optionD: false,
	});

	const handleChange = (event) => {
		setState({ [event.target.name]: event.target.checked });
	};

	const { optionA, optionB, optionC, optionD } = state;
	const Optionerror =
		[optionA, optionB, optionC, optionD].filter((v) => v).length !== 1;

	const history = useHistory();

	const retrieveQuiz = () => {
		if (id) {
			try {
				setLoading(true);
				firebase
					.database()
					.ref('quiz')
					.on('value', async (snapshot) => {
						const quizArray =
							snapshot.val() !== null
								? Object.entries(snapshot.val()).filter((e) => e[0] === id)
								: [];
						if (quizArray[0][1]) {
							const { question, answer, options, images } = quizArray[0][1];

							setInitialValues({
								question,
								optionA: options[0] || '',
								optionB: options[1] || '',
								optionC: options[2] || '',
								optionD: options[3] || '',
								answer,
								images,
							});
							setState({
								optionA: options[0] === answer,
								optionB: options[1] === answer,
								optionC: options[2] === answer,
								optionD: options[3] === answer,
							});
							setLoading(false);
						}
					});
			} catch (err) {
				setLoading(false);
				console.log(err);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		retrieveQuiz();
	}, [id]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object({
			question: Yup.string()
				.min(20, 'Must be 20 characters or more')
				.required('Required'),
			optionA: Yup.string().required('Required'),
			optionB: Yup.string().required('Required'),
			optionC: Yup.string().required('Required'),
			optionD: Yup.string(),
		}),
		onSubmit: async (values) => {
			if (Optionerror) return;
			setCreateError();
			setSubmitting(true);

			let imagesUrl = [];

			if (images && images.length > 0) {
				try {
					const result = await Promise.all(
						images.map(async (image) => {
							const item = await getBase64(image);
							return item;
						})
					);

					imagesUrl = await Promise.all(
						result.map(async (image) => {
							const url = await uploadImages(image);
							return url;
						})
					);
				} catch (error) {
					console.log(error);
				}
			}

			const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
				'x'
			);

			const { optionA, optionB, optionC, optionD } = values;

			const quizId = `${timestamp}||${uuidv4()}`;
			const answer = values[Object.keys(state)[0]];
			const payload = {
				question: values?.question,
				options: [
					...(optionA.length ? [optionA] : []),
					...(optionB.length ? [optionB] : []),
					...(optionC.length ? [optionC] : []),
					...(optionD.length ? [optionD] : []),
				],
				answer,
			};

			await firebase
				.database()
				.ref(`quiz/${id || quizId}`)
				.set({
					...payload,
					timestamp,
					id: id || quizId,
					expired: false,
					images: imagesUrl.length
						? imagesUrl[0]
						: 'https://res.cloudinary.com/djqhkyffp/image/upload/v1670341843/mobivet/Updated_Compressed_Quiz_Banner_h9ioh3.png',
					creator: user?.store,
				})
				.then(async () => {
					history.push('/admins/quiz');
					setSubmitting(false);
				})
				.catch((error) => {
					console.log(error.message);
					setCreateError(error.message);
					setSubmitting(false);
				});
		},
		enableReinitialize: true,
	});

	const onImageUpload = async (files) => {
		setImages(files);
	};

	const sharedClasses = sharedStyles();

	return loading ? (
		<div className={classes.loading}>
			<CircularProgress />
		</div>
	) : (
		<div className={classes.addProductWrapper}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.title}>Create a Quiz</Typography>
				<form className={classes.form} onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='question'
								label='Question'
								name='question'
								multiline
								rows={6}
								value={formik.values.question}
								error={formik.touched.question && formik.errors.question}
								helperText={
									formik.touched.question && formik.errors.question
										? formik.errors.question
										: null
								}
								{...formik.getFieldProps('question')}
							/>
						</Grid>
						{['A', 'B', 'C', 'D'].map((x, key) => {
							const name = 'option' + x;
							const label = 'Option ' + x;
							return (
								<Grid item xs={12} key={key + x}>
									<TextField
										variant='outlined'
										fullWidth
										id={name}
										label={label}
										name={name}
										error={formik.touched[name] && formik.errors[name]}
										helperText={
											formik.touched[name] && formik.errors[name]
												? formik.errors[name]
												: null
										}
										{...formik.getFieldProps(name)}
									/>
								</Grid>
							);
						})}
						<Grid item xs={12}>
							<FormControl
								required
								error={Optionerror}
								component='fieldset'
								className={classes.formControl}
								row
							>
								<FormLabel component='legend'>Pick an answer</FormLabel>
								<FormGroup row>
									{['A', 'B', 'C', 'D'].map((x, key) => {
										const name = 'option' + x;
										const label = 'Option ' + x;
										return (
											<FormControlLabel
												key={key}
												control={
													<Checkbox
														checked={state[name]}
														onChange={handleChange}
														name={name}
														disabled={!formik.values[name]}
													/>
												}
												label={label}
											/>
										);
									})}
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<DropzoneArea
								acceptedFiles={['image/*']}
								dropzoneText={'Drag and drop quiz image here or click'}
								onChange={(files) => onImageUpload(files)}
								style={{ borderColor: '#ED7922' }}
								initialFiles={[initialValues?.images]}
							/>
						</Grid>
					</Grid>
					<Typography
						style={{
							textAlign: 'center',
							color: '#ff0000',
							fontSize: 14,
							marginTop: 10,
						}}
					>
						{createError && createError}
					</Typography>
					<div>
						<Button
							onClick={() => null}
							fullWidth
							loading={submitting}
							disabled={submitting}
							variant='contained'
							className={classes.submit}
							type='submit'
						>
							{!submitting ? (
								'Create Quiz'
							) : (
								<CircularProgress color='primary' size={20} />
							)}
						</Button>
						<Navigate to='/admins/quiz' className={classes.link}>
							<Button
								onClick={() => null}
								fullWidth
								loading={submitting.toString()}
								disabled={submitting}
								color='primary'
								variant='outlined'
								className={classes.cancel}
							>
								Cancel
							</Button>
						</Navigate>
					</div>
				</form>
			</div>
		</div>
	);
}
