import React from 'react';
import { Redirect } from 'react-router';

const AuthRoute = () => {
	const foundUser = localStorage.getItem('user');
	const user = JSON.parse(foundUser);
	if (user) {
		if (user?.type === 'admin') {
			return <Redirect to='/admins/home' />;
		}
		return <Redirect to='/home' />;
	} else {
		return <Redirect to='/login' />;
	}
};

export default AuthRoute;
