import { makeStyles } from '@material-ui/core/styles';

import AccountProfileDetails from './AccountProfileDetails';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 230,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	table: {
		width: '30%',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 55,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
		},
	},
}));

const Account = () => {
	const classes = useStyles();
	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={classes.body}>
				<AccountProfileDetails />
			</div>
		</div>
	);
};

export default Account;
