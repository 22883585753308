/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import 'firebase/database';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function QuizaCard({ data }) {
	const { width } = useWindowDimensions();
	const [submitting, setSubmitting] = useState(false);

	const history = useHistory();

	const useStyles = makeStyles((theme) => ({
		root: {
			backgroundColor: 'white',
			cursor: 'default',
			borderRadius: 10,
			width: '35%',
			[theme.breakpoints.down('sm')]: {
				width: (width * 85) / 100,
				marginBottom: theme.spacing(2),
			},
			shadowColor: '#00000030',
			shadowOpacity: 0.5,
			boxShadow:
				'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
		},
		media: {
			height: 200,
			width: '100%',
		},
		title: {
			fontSize: 12,
			fontWeight: 'bold',
			color: '#033860',
		},
		answer: {
			fontSize: 12,
			fontWeight: 'bold',
			color: '#588908',
		},
		grid: {
			marginTop: theme.spacing(2),
		},
		text: {
			color: '#1e7aa8',
			fontWeight: 'bold',
			fontSize: 128,
		},
		question: {
			color: '#033860',
		},
		footer: {
			backgroundColor: '#033860',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		button: {
			color: 'white',
			borderRadius: 5,
			fontWeight: 'bold',
		},
	}));

	const onDelete = async () => {
		setSubmitting(true);
		await firebase
			.database()
			.ref(`quiz/${data.id}`)
			.set({
				...data,
				expired: true,
			})
			.then(async () => {
				setSubmitting(false);
			})
			.catch((error) => {
				console.log(error.message);
				setSubmitting(false);
			});
	};

	const classes = useStyles();
	return (
		<Card className={classes.root}>
			<div>
				<CardMedia
					className={classes.media}
					image={data?.images}
					title='Quiz cover photo'
				/>
				<CardContent className={classes.content}>
					<Typography
						gutterBottom
						variant='h5'
						component='h2'
						className={classes.question}
					>
						{data?.question}
					</Typography>
					<Grid item xs={12} md={6} className={classes.grid}>
						<Typography className={classes.title}>Choices:</Typography>
						<div className={classes.demo}>
							<List dense>
								{data?.options.map((item, idx) => (
									<ListItem className={classes.text} key={item + idx}>
										<ListItemText>{item}</ListItemText>
									</ListItem>
								))}
							</List>
						</div>
					</Grid>
					<Typography
						gutterBottom
						variant='h5'
						component='h2'
						className={classes.answer}
					>
						{data?.answer}
					</Typography>
				</CardContent>
			</div>
			<CardActions className={classes.footer}>
				<Button
					variant='contained'
					size='small'
					color='primary'
					className={classes.button}
					style={{ backgroundColor: '#88B12E' }}
					onClick={() => history.push('/admins/update-quiz/' + data?.id)}
				>
					Edit
				</Button>
				<Button
					variant='contained'
					size='small'
					className={classes.button}
					style={{ backgroundColor: '#d89447' }}
					loading={submitting.toString()}
					disabled={submitting}
					onClick={onDelete}
				>
					Delete
				</Button>
			</CardActions>
		</Card>
	);
}
