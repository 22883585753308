const getBase64 = (file) =>
	new Promise((resolve) => {
		let baseURL = '';
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			baseURL = reader.result;

			resolve(baseURL);
		};
	});

export default getBase64;
