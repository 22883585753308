import { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import sendPushNotification from '../../../../helpers/sendPushNotification';

const customStyles = makeStyles((theme) => ({
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 10,
		fontSize: 10,
	},
	submit: {
		margin: theme.spacing(0, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 10,
		width: 173,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
	cancel: {
		margin: theme.spacing(0, 1, 2),
		flex: 1,
		borderRadius: 10,
		width: 150,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
}));

export default function UpdateStatus({ item }) {
	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [status, setStatus] = useState();
	const [isError, setIsError] = useState(false);

	const classes = customStyles();

	const onUpdateOrder = async () => {
		setSubmitting(true);
		setIsError(false);
		if (!status) {
			setIsError(true);
			setSubmitting(false);
			return;
		}
		const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
			'x'
		);

		const payload = {
			...item,
			tracking: {
				...item?.tracking,
				[timestamp]: {
					message: status,
					timestamp,
				},
			},
		};
		await firebase
			.database()
			.ref(`orders/${item.id}`)
			.update(payload)
			.then(async () => {
				setSubmitting(false);
				setOpen(false);
				await sendPushNotification(
					item?.buyer?.notification_token,
					'Order status',
					`${item?.product}: ${status}`,
					{
						screen: 'APP',
						subScreen: 'SHOP',
						data: {},
					}
				);
			})
			.catch((err) => {
				setSubmitting(false);
				console.log('error ', err);
			});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				onClick={handleClickOpen}
				variant='contained'
				className={classes.submit}
				type='submit'
				fullWidth
			>
				{!submitting ? (
					'Update Status'
				) : (
					<CircularProgress color='primary' size={20} />
				)}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Update Order Status</DialogTitle>
				<DialogContent>
					<DialogContentText>Type the new order status</DialogContentText>
					<TextField
						autoFocus
						margin='dense'
						id='name'
						label='Order status'
						type='text'
						fullWidth
						onChange={(e) => setStatus(e.target.value)}
						error={isError}
						helperText={isError && 'Please enter the status'}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
						variant='outlined'
						className={classes.cancel}
					>
						Cancel
					</Button>
					<Button
						onClick={onUpdateOrder}
						variant='contained'
						loading={submitting}
						disabled={submitting}
						className={classes.submit}
						type='submit'
						fullWidth
					>
						{!submitting ? (
							'Update'
						) : (
							<CircularProgress color='primary' size={20} />
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

UpdateStatus.propTypes = {
	item: PropTypes.any,
};
