import { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { useUpdateContext } from '../data/AppContext';
import routes from './routes';
import RouteWithSubRoutes from './RouteWithSubRoutes';

function App() {
	const update = useUpdateContext();
	useEffect(() => {
		const loggedInUser = localStorage.getItem('user');
		const foundUser = JSON.parse(loggedInUser);
		if (foundUser?.uid) {
			update(foundUser);
		}
	}, []);
	return (
		<div className='wrapper'>
			<BrowserRouter>
				<Switch>
					{routes.map((route, i) => (
						<RouteWithSubRoutes key={i} {...route} />
					))}
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
