import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#88B12E',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20,
		paddingBottom: 50,
		backgroundImage: `url("https://res.cloudinary.com/djqhkyffp/image/upload/b_rgb:000000,o_30/v1620391985/1-1920x1080-03b81ba0f842b24ad3eda5c2e99b0d48_melvv3.jpg")`,
		// backgroundImage: `url("https://res.cloudinary.com/djqhkyffp/image/upload/b_rgb:88b12e,o_60/v1620390581/pexels-photo-1036857_pwocnf.jpg")`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: '#FFFFFF',
		boxShadow: '0px 0px 15px #00000017',
		borderRadius: 40,
		paddingBottom: 20,
		margin: 20,
	},
	paper: {
		margin: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#145104',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(3),
		alignItems: 'center',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 20,
		width: 173,
	},
	fullSubmit: {
		margin: theme.spacing(3, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 20,
	},
	logo: {
		margin: theme.spacing(2),
		width: 100,
		height: 100,
	},
	created: {
		marginBottom: theme.spacing(2),
		width: 300,
		height: 200,
	},
	registered: {
		display: 'flex',
	},
	optionsText: {
		textAlign: 'center',
		marginTop: 15,
		color: '#88B12E',
		fontWeight: 'bold',
	},
	link: { textDecoration: 'none' },
}));

export default useStyles;
