import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as Navigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import sharedStyles from '../shared/styles';
import AnswerTable from '../shared/Tables/AnswerTable';
import firebase from 'firebase/app';
import 'firebase/database';
import QuizaCard from './Quiz/Card';
import { QuestionAnswerRounded } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 230,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	table: {
		width: '30%',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: 150,
			height: 90,
			marginBottom: 20,
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 20,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
		},
	},
}));

const Quiz = () => {
	const sharedClasses = sharedStyles();
	const classes = useStyles();

	const [data, setData] = useState([]);
	const [quiz, setQuiz] = useState();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		retrieveQuiz();
	}, []);

	const retrieveQuiz = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('quiz')
				.on('value', async (snapshot) => {
					const quizArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);
					quizArray.sort((a, b) => b.timestamp - a.timestamp);
					quizArray.length && quizArray[0]?.expired !== true
						? setQuiz(quizArray[0])
						: setQuiz();
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	useEffect(() => {
		quiz?.id && retriveQuestionAnswers();

		return () =>
			firebase
				.database()
				.ref('quiz-answer')
				.off('value', retriveQuestionAnswers);
	}, [quiz?.id]);

	const retriveQuestionAnswers = () => {
		try {
			firebase
				.database()
				.ref('quiz-answer')
				.orderByChild('quizId')
				.equalTo(quiz?.id)
				.on('value', (snapshot) => {
					const ansrArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					const sortedArray = ansrArray
						.sort((a, b) => b?.timestamp - a?.timestamp)
						.filter((item) => item?.correct);
					sortedArray.forEach((x) => {
						x.date = moment(new Date(+x?.timestamp)).format('lll');
					});
					setData(sortedArray);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.mainText}>Quiz Polls Section</Typography>
				<div className={classes.addNewDiv}>
					<Typography className={classes.text}>
						Click here to Create a Quiz
					</Typography>
					<Navigate to='/admins/add-quiz' style={{ textDecoration: 'none' }}>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							endIcon={<AddIcon />}
						>
							Add
						</Button>
					</Navigate>
				</div>
			</div>
			<div className={classes.body}>
				{!quiz ? (
					<div
						className={`${sharedClasses.containerStyle} ${classes.productsCounter}`}
					>
						<QuestionAnswerRounded className={classes.counterIcon} />
						<div className={classes.counter}>
							<Typography className={classes.text}>Quiz</Typography>
							{loading ? (
								<CircularProgress />
							) : (
								<Typography className={classes.counterText}>No Data</Typography>
							)}
						</div>
					</div>
				) : (
					<QuizaCard data={quiz} loading={loading} />
				)}
				{data && <AnswerTable data={data} loading={loading} />}
			</div>
		</div>
	);
};

export default Quiz;
