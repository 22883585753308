import { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import sharedStyles from '../../shared/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import firebase from 'firebase/app';
import 'firebase/database';
import { useAppContext } from '../../../data/AppContext';

const useStyles = makeStyles((themes) => ({
	mainText: {
		fontSize: '25px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 20,
		},
	},
	text: {
		fontSize: '19px',
		fontWeight: '200',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	addNewDiv: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 20,
	},
	body: {
		marginTop: 30,
		marginBottom: 20,
		width: '100%',
		justifyContent: 'space-around',
		display: 'flex',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	productsCounter: {
		width: 340,
		height: 150,
		marginRight: 20,
		display: 'flex',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 20,
			marginRight: 0,
			flexDirection: 'column',
			alignSelf: 'center',
		},
	},
	counter: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		[themes.breakpoints.down('sm')]: {
			flexDirection: 'row',
			justifyContent: 'center',
		},
	},
	counterIcon: {
		fontSize: 80,
		[themes.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	counterText: {
		fontSize: 55,
		fontFamily: 'Poppins',
		fontWeight: '500',
		color: '#1A1A1A',
		[themes.breakpoints.down('sm')]: {
			fontSize: 25,
			marginLeft: 10,
		},
	},
	status: {
		display: 'flex',
		alignItems: 'center',
	},
	dot: {
		marginRight: 5,
		fontSize: 19,
	},
	notificationCard: {
		width: 600,
		display: 'flex',
		height: 100,
		marginBottom: 20,
		flexDirection: 'column',
		justifyContent: 'space-around',
		[themes.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 20,
			height: 100,
			flexDirection: 'column',
		},
	},
	headers: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-start',
		alignContent: 'center',
	},
	notIcon: {
		color: '#449089',
		fontSize: '19px',
		fontFamily: 'Poppins',
		textAlign: 'center',
		marginTop: 3,
	},
	headerText: {
		color: '#516A18',
		fontSize: '18px',
		fontFamily: 'Poppins',
		fontWeight: '500',
		marginLeft: 10,
	},
	date: {
		color: '#7CB057',
		fontFamily: 'Poppins',
		fontWeight: '300',
		fontSize: '9px',
		textAlign: 'center',
		marginLeft: 10,
		marginTop: 10,
	},
	cancel: {
		padding: 0,
		color: '#93BC38',
	},
}));

const Products = () => {
	const sharedClasses = sharedStyles();
	const classes = useStyles();
	const user = useAppContext();

	const [data, setData] = useState([]);
	const [notifications, setNotifications] = useState([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		retrieveProducts();
		retrieveNotifications();
	}, [user]);

	const filterUserProducts = async (products) => {
		const filteredProducts = await products.filter(
			(item) => item?.seller?.uid === user?.uid
		);

		const pending = await filteredProducts.filter(
			(item) => item?.status === 'Pending'
		);

		const approved = await filteredProducts.filter(
			(item) => item?.status === 'Approved'
		);

		const declined = await filteredProducts.filter(
			(item) => item?.status === 'Declined'
		);

		return {
			declined: declined.length,
			approved: approved.length,
			pending: pending.length,
			total: filteredProducts.length,
		};
	};

	const retrieveProducts = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref('orders')
				.on('value', async (snapshot) => {
					const productsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);

					const filteredData = await filterUserProducts(productsArray);

					setData(filteredData);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const retrieveNotifications = () => {
		try {
			setLoading(true);
			firebase
				.database()
				.ref(`market-notifications/${user?.uid}`)
				.on('value', async (snapshot) => {
					const notificationsArray =
						snapshot.val() !== null
							? Object.entries(snapshot.val()).map((e) => e[1])
							: [];
					setLoading(false);
					notificationsArray.sort((a, b) => b.timestamp - a.timestamp);
					setNotifications(notificationsArray);
				});
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	return (
		<div style={{ width: '100%', marginTop: 30 }}>
			<div className={classes.body}>
				<div
					className={`${sharedClasses.containerStyle} ${classes.productsCounter}`}
				>
					<div>
						<div className={classes.status}>
							<FiberManualRecordIcon
								className={classes.dot}
								style={{ color: '#FFE200' }}
							/>
							<Typography className={classes.text}>
								Pending {`(${data?.pending || 0})`}
							</Typography>
						</div>
						<div className={classes.status}>
							<FiberManualRecordIcon className={classes.dot} />
							<Typography className={classes.text}>
								Approved {`(${data?.approved || 0})`}
							</Typography>
						</div>
						<div className={classes.status}>
							<FiberManualRecordIcon
								className={classes.dot}
								style={{ color: '#FF5800' }}
							/>
							<Typography className={classes.text}>
								Declined {`(${data?.declined || 0})`}
							</Typography>
						</div>
					</div>
					<div className={classes.counter}>
						<Typography className={classes.text}>Total Orders</Typography>

						{loading ? (
							<CircularProgress />
						) : (
							<Typography className={classes.counterText}>
								{data?.total || 0}
							</Typography>
						)}
					</div>
				</div>
				<div>
					{notifications &&
						notifications.map((item) => (
							<div style={{ width: '100%' }}>
								<Badge
									color='secondary'
									invisible
									badgeContent={<CancelIcon className={classes.cancel} />}
									style={{
										width: '100%',
										color: 'white',
										padding: 0,
									}}
								>
									<div
										className={`${sharedClasses.containerStyle} ${classes.notificationCard}`}
										key={item}
									>
										{/* <CancelIcon className={classes.cancel} /> */}
										<div className={classes.headers}>
											<NotificationsIcon className={classes.notIcon} />
											<Typography className={classes.headerText}>
												{item?.title}
											</Typography>
											<Typography className={classes.date}>
												{item.timestamp
													? moment
															.unix(item.timestamp / 1000)
															.format('ll HH:mm')
													: ''}
											</Typography>
										</div>
										<Typography className={classes.text}>
											{item?.message}
										</Typography>
									</div>
								</Badge>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Products;
