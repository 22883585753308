import firebase from 'firebase/app';
import 'firebase/storage';

const uploadVideo = (file, setProgress) =>
	new Promise((resolve, reject) => {
		// Create a reference to the location where the file will be uploaded
		const storageRef = firebase.storage().ref().child(`videos/${file.name}`);

		// Upload the file to the location specified in the reference
		const uploadTask = storageRef.put(file);

		// Update the progress value as the file uploads
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setProgress(progress);
			},
			(error) => {
				reject(error);
			},
			() => {
				// Return the download URL of the uploaded video
				uploadTask.snapshot.ref
					.getDownloadURL()
					.then((downloadURL) => {
						console.log('Video uploaded successfully!');
						resolve(downloadURL);
					})
					.catch((error) => {
						reject(error);
					});
			}
		);
	});

export default uploadVideo;
