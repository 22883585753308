async function sendPushNotification(token, title, body, data) {
	const message = {
		to: token,
		sound: 'default',
		title,
		body,
		data,
	};

	const res = await fetch('https://exp.host/--/api/v2/push/send', {
		mode: 'no-cors',
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Accept-encoding': 'gzip, deflate',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(message),
	});

	return res;
}

export default sendPushNotification;
