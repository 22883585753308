import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';
import firebase from 'firebase/app';
import 'firebase/storage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '50%',
		borderRadius: '10px',
		outline: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	dropzone: {
		minHeight: 200,
	},
	preview: {
		display: 'block',
		maxWidth: '100%',
		maxHeight: '400px',
	},
	button: {
		marginTop: 20,
		color: 'white',
		borderRadius: 20,
		alignSelf: 'center',
		fontWeight: 'bold',
	},
}));

// eslint-disable-next-line react/prop-types
const BannerModal = ({ open, onClose }) => {
	const classes = useStyles();
	const [banner, setBanner] = useState(null);
	const [existingBannerUrl, setExistingBannerUrl] = useState('');
	const [isAdding, setIsAdding] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		const storageRef = firebase.storage().ref('banner.jpg');
		storageRef.getDownloadURL().then((url) => {
			setExistingBannerUrl(url);
		});
	}, []);

	const handleBannerChange = (files) => {
		if (files.length > 0) {
			setBanner(files[0]);
		}
	};

	const handleDeleteBanner = () => {
		setIsDeleting(true);
		const storageRef = firebase.storage().ref('banner.jpg');
		storageRef.delete().then(() => {
			setExistingBannerUrl('');
		});
		setIsDeleting(false);
	};

	const handleUploadBanner = () => {
		setIsAdding(true);
		if (banner) {
			const storageRef = firebase.storage().ref('banner.jpg');
			storageRef.put(banner).then(() => {
				setExistingBannerUrl(URL.createObjectURL(banner));
			});
		}
		setIsAdding(false);
	};

	return (
		<Modal open={open} onClose={onClose} className={classes.modal}>
			<div className={classes.paper}>
				<h2>Banner Image</h2>
				{existingBannerUrl ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<img
							src={existingBannerUrl}
							alt='Existing banner'
							className={classes.preview}
						/>
						<Button
							variant='contained'
							size='small'
							className={classes.button}
							style={{ backgroundColor: '#d89447' }}
							onClick={handleDeleteBanner}
							endIcon={<DeleteForever />}
							disabled={isDeleting}
						>
							Delete Banner
						</Button>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<DropzoneArea
							filesLimit={1}
							acceptedFiles={['image/*']}
							onChange={handleBannerChange}
							dropzoneClass={classes.dropzone}
						/>
						{banner && (
							<Button
								variant='contained'
								color='primary'
								className={classes.button}
								endIcon={<CloudUploadIcon />}
								onClick={handleUploadBanner}
								disabled={isAdding}
							>
								Upload Banner
							</Button>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default BannerModal;
