const upload = async (file) => {
	const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/djqhkyffp/upload';
	let response;
	const data = {
		file,
		upload_preset: 'pjwk6uma',
	};

	await fetch(CLOUDINARY_URL, {
		body: JSON.stringify(data),
		headers: {
			'content-type': 'application/json',
		},
		method: 'POST',
	})
		.then(async (r) => {
			response = await r.json();
		})
		.catch((err) => console.log(err));

	return response.url;
};

export default upload;
