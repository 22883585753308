import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/database';
import useStyles from '../Products/useStyles';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	CircularProgress,
	Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TextField from '../Products/TextField';
import { useAppContext, useUpdateContext } from '../../../data/AppContext';

const states = [
	{
		value: 'Arusha',
		label: 'Arusha',
	},
	{
		value: 'Dar es Salaam',
		label: 'Dar es Salaam',
	},
	{
		value: 'Dodoma',
		label: 'Dodoma',
	},
	{
		value: 'Geita',
		label: 'Geita',
	},
	{
		value: 'Iringa',
		label: 'Iringa',
	},
	{
		value: 'Kagera',
		label: 'Kagera',
	},
	{
		value: 'Katavi',
		label: 'Katavi',
	},
	{
		value: 'Kigoma',
		label: 'Kigoma',
	},
	{
		value: 'Kilimanjaro',
		label: 'Kilimanjaro',
	},
	{
		value: 'Lindi',
		label: 'Lindi',
	},
	{
		value: 'Manyara',
		label: 'Manyara',
	},
	{
		value: 'Mara',
		label: 'Mara',
	},
	{
		value: 'Mbeya',
		label: 'Mbeya',
	},
	{
		value: 'Mjini Magharibi',
		label: 'Mjini Magharibi',
	},
	{
		value: 'Morogoro',
		label: 'Morogoro',
	},
	{
		value: 'Mtwara',
		label: 'Mtwara',
	},
	{
		value: 'Mwanza',
		label: 'Mwanza',
	},
	{
		value: 'Njombe',
		label: 'Njombe',
	},
	{
		value: 'Pemba North',
		label: 'Pemba North',
	},
	{
		value: 'Pemba South',
		label: 'Pemba South',
	},
	{
		value: 'Pwani',
		label: 'Pwani',
	},
	{
		value: 'Rukwa',
		label: 'Rukwa',
	},
	{
		value: 'Ruvuma',
		label: 'Ruvuma',
	},
	{
		value: 'Shinyanga',
		label: 'Shinyanga',
	},
	{
		value: 'Simiyu',
		label: 'Simiyu',
	},
	{
		value: 'Singida',
		label: 'Singida',
	},
	{
		value: 'Songwe',
		label: 'Songwe',
	},
	{
		value: 'Tabora',
		label: 'Tabora',
	},
	{
		value: 'Tanga',
		label: 'Tanga',
	},
	{
		value: 'Unguja North',
		label: 'Unguja North',
	},
	{
		value: 'Unguja South',
		label: 'Unguja South',
	},
];

const AccountProfileDetails = () => {
	const user = useAppContext();
	const update = useUpdateContext();
	const [submitting, setSubmitting] = useState(false),
		[_updateError, setUpdateError] = useState(),
		[inputs, setInputs] = useState(user);

	const [open, setOpen] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		setInputs(user);
	}, [user]);

	const classes = useStyles();

	const phoneRegExp = /^\d{10}$/;

	const formik = useFormik({
		initialValues: {
			...inputs,
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			store: Yup.string().required('Required'),
			country: Yup.string()
				.min(3, 'Must be 3 characters or more')
				.required('Required'),
			city: Yup.string()
				.min(3, 'Must be 3 characters or more')
				.required('Required'),
			phone: Yup.string()
				.required('Required')
				.matches(phoneRegExp, 'Phone number is not valid'),
		}),
		onSubmit: async (values) => {
			setUpdateError();
			setSubmitting(true);
			await firebase
				.database()
				.ref(`market_users/${user.uid}`)
				.update(values)
				.then(async () => {
					await localStorage.setItem('user', JSON.stringify(values));
					update(values);
					setOpen(true);
					setSubmitting(false);
				})
				.catch((error) => {
					console.log(error.message);
					setUpdateError(error.message);
					setSubmitting(false);
				});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Card>
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert onClose={handleClose} severity='success'>
						Profile is updated
					</Alert>
				</Snackbar>
				<CardHeader subheader='The information can be edited' title='Account' />
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Store name'
								name='store'
								variant='outlined'
								error={formik.touched.store && formik.errors.store}
								helperText={
									formik.touched.store && formik.errors.store
										? formik.errors.store
										: null
								}
								{...formik.getFieldProps('store')}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Email Address'
								name='email'
								variant='outlined'
								error={formik.touched.email && formik.errors.email}
								helperText={
									formik.touched.email && formik.errors.email
										? formik.errors.email
										: null
								}
								{...formik.getFieldProps('email')}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Phone Number'
								name='phone'
								type='phone'
								variant='outlined'
								error={formik.touched.phone && formik.errors.phone}
								helperText={
									formik.touched.phone && formik.errors.phone
										? formik.errors.phone
										: null
								}
								{...formik.getFieldProps('phone')}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Country'
								name='country'
								variant='outlined'
								error={formik.touched.country && formik.errors.country}
								helperText={
									formik.touched.country && formik.errors.country
										? formik.errors.country
										: null
								}
								{...formik.getFieldProps('country')}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Select City'
								name='city'
								required
								select
								SelectProps={{ native: true }}
								variant='outlined'
								error={formik.touched.city && formik.errors.city}
								helperText={
									formik.touched.city && formik.errors.city
										? formik.errors.city
										: null
								}
								{...formik.getFieldProps('city')}
							>
								{states.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label='Street Number'
								name='street'
								variant='outlined'
								{...formik.getFieldProps('street')}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Grid item md={6} xs={12}>
					<Button
						onClick={() => null}
						variant='contained'
						className={classes.submit}
						type='submit'
						style={{ margin: 20, width: '93%', alignSelf: 'center' }}
					>
						{!submitting ? (
							'	Save details'
						) : (
							<CircularProgress color='primary' size={20} />
						)}
					</Button>
				</Grid>
			</Card>
		</form>
	);
};

export default AccountProfileDetails;
