import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as Navigate, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalHospitalTwoToneIcon from '@material-ui/icons/LocalHospitalTwoTone';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import QuizIcon from '@material-ui/icons/Poll';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Switch, useHistory } from 'react-router-dom';
import RouteWithSubRoutes from '../../Navigation/RouteWithSubRoutes';
import { useAppContext, useUpdateContext } from '../../data/AppContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		backgroundColor: 'white',
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - 350px)`,
			marginLeft: '350px',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		backgroundColor: '#93BC38',
		borderRight: ' #FFF',
		[theme.breakpoints.up('sm')]: {
			width: 350,
		},
	},
	header: {
		...theme.mixins.toolbar,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingLeft: 150,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(3),
		},
	},
	logo: {
		margin: theme.spacing(2),
		width: 100,
		height: 100,
	},
	list: {
		marginTop: 50,
	},
	listText: {
		color: 'white',
		textTransform: 'uppercase',
		fontFamily: 'Poppins',
		fontSize: '20px',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
			marginRight: 5,
		},
	},
	selectedItem: {
		position: 'absolute',
		right: 0,
		width: 0,
		height: 0,
		borderTop: '25px solid transparent',
		borderRight: '50px solid #FFF',
		borderBottom: '25px solid transparent',
		[theme.breakpoints.down('sm')]: {
			borderTop: '10px solid transparent',
			borderRight: '20px solid #FFF',
			borderBottom: '10px solid transparent',
		},
	},
	listItem: {
		marginBottom: 20,
	},
	sideBarIcon: {
		fontSize: 30,
		color: 'white',
	},
	toolBarIcon: {
		display: 'flex',
		alignItems: 'center',
	},
	user: {
		textAlign: 'center',
		color: '#1A1A1A',
		fontSize: '20px',
		fontFamily: 'Poppins',
		fontWeight: '300',
	},
	link: { textDecoration: 'none' },
}));

function ResponsiveDrawer(props) {
	const { window, routes } = props;

	const user = useAppContext();
	const update = useUpdateContext();
	const history = useHistory();
	const location = useLocation();

	console.log(location.pathname.includes('admin'));
	useEffect(() => {
		if (!user.uid) {
			const loggedInUser = localStorage.getItem('user');
			const foundUser = JSON.parse(loggedInUser);
			if (foundUser?.uid && foundUser.isVerified) {
				update(foundUser);
				if (foundUser?.type !== 'admin') history.push('/home');
			} else {
				history.push('/login');
			}
		}
	}, []);

	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [_selected, setSelected] = useState(0);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const logo =
		'https://res.cloudinary.com/djqhkyffp/image/upload/v1629826406/mobivet/inside_1_oyrgrh.png';

	const sideBarItems = [
		{ name: 'Products', Icon: StoreIcon, link: '/admins/home' },
		{ name: 'Orders', Icon: ShoppingCartIcon, link: '/admins/orders' },
		{
			name: 'Experts',
			Icon: LocalHospitalTwoToneIcon,
			link: '/admins/vet',
		},
		{
			name: 'Suppliers',
			Icon: LocalShippingIcon,
			link: '/admins/suppliers',
		},
		{
			name: 'Video repository',
			Icon: VideoLibraryIcon,
			link: '/admins/videos',
		},
		{
			name: 'Video subscription',
			Icon: SubscriptionsIcon,
			link: '/admins/subscription',
		},
		{
			name: 'Quiz Polls',
			Icon: QuizIcon,
			link: '/admins/quiz',
		},
		{ name: 'Account', Icon: PersonIcon, link: '/admins/account' },
	];

	const onLogout = async () => {
		localStorage.clear();
		history.push('/login');
	};

	const drawer = (
		<div>
			<div className={classes.header}>
				<img src={logo} alt='chui logo' className={classes.logo} />
			</div>
			<List className={classes.list}>
				{sideBarItems.map((item, index) => (
					<Navigate to={item.link} className={classes.link}>
						<ListItem
							button
							key={item.name}
							onClick={() => setSelected(index)}
							className={classes.listItem}
						>
							<ListItemIcon>
								<item.Icon className={classes.sideBarIcon} />
							</ListItemIcon>

							<ListItemText
								disableTypography
								primary={
									<Typography type='body2' className={classes.listText}>
										{item.name}
									</Typography>
								}
							/>
							<div
								className={
									location.pathname.includes(item.link) && classes.selectedItem
								}
							/>
						</ListItem>
					</Navigate>
				))}
			</List>
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar style={{ justifyContent: 'space-between' }}>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<div />
					<div className={classes.toolBarIcon}>
						<IconButton edge='end'>
							<PersonIcon style={{ color: '#2F2F2F', fontSize: 30 }} />
						</IconButton>
						<Typography className={classes.user}>Admins</Typography>
						<Button
							style={{ color: '#1A1A1A', marginLeft: 20 }}
							onClick={onLogout}
						>
							Logout
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label='mailbox folders'>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation='css'>
					<Drawer
						container={container}
						variant='temporary'
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation='css'>
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant='permanent'
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Switch>
					{routes.map((route, i) => (
						<RouteWithSubRoutes key={i} {...route} />
					))}
				</Switch>
			</main>
		</div>
	);
}

ResponsiveDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
	routes: PropTypes.any,
};

export default ResponsiveDrawer;
