import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import sharedStyles from '../../shared/styles';
import Button from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmptyOrder from './EmptyOrder';
import sendPushNotification from '../../../helpers/sendPushNotification';

const customStyles = makeStyles((theme) => ({
	button: {
		marginLeft: 20,
		color: 'white',
		borderRadius: 10,
		fontSize: 10,
	},
	root: {
		alignSelf: 'center',
		alignItems: 'center',
		padding: '5%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: 0,
		},
	},
	tabs: {
		height: 60,
		borderRadius: 0,
		display: 'flex',
		alignSelf: 'center',
		alignItems: 'center',
		width: '100%',
		backgroundColor: 'white',
		shadowColor: '#00000030',
		shadowOpacity: 0.3,
		boxShadow:
			'0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
		[theme.breakpoints.down('sm')]: {
			height: 40,
		},
	},
	tab: {
		height: '100%',
		flex: 1,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tabText: {
		fontSize: '18px',
		color: '#1A1A1A',
		fontFamily: 'Poppins',
		fontWeight: '300',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
	dots: {
		fontSize: 17,
		color: 'blue',
		marginRight: 10,
		[theme.breakpoints.down('sm')]: {
			marginRight: 3,
		},
	},
	cards: {
		marginTop: 70,
	},
	cardBottom: {
		height: 20,
		backgroundColor: '#FFE200',
		borderRadius: '0 0px 10px 10px',
	},
	contentWrapper: {
		width: '100%',
		padding: '0 30px 30px 30px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		marginTop: 5,
		justifyContent: 'space-around',
	},
	title: {
		flex: 1,
		fontSize: 16,
		fontWeight: '600',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
	},
	content: {
		flex: 2,
		fontSize: 16,
		fontWeight: '400',
		fontFamily: 'Poppins',
		color: '#1A1A1A',
	},
	orderId: {
		fontSize: '18px',
		color: '#1A1A1A',
		fontFamily: 'Poppins',
		fontWeight: '600',
		margin: '20px 0 20px 0',
		[theme.breakpoints.down('sm')]: {
			fontSize: '15px',
		},
	},
	orderDetails: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	productDetails: {
		margin: 20,
		flex: 1,
		flexDirection: 'column',
	},
	submit: {
		margin: theme.spacing(0, 0, 2),
		flex: 1,
		backgroundColor: '#93BC38',
		color: '#FFFFFF',
		borderRadius: 10,
		width: 173,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
	cancel: {
		margin: theme.spacing(0, 1, 2),
		flex: 1,
		borderRadius: 10,
		width: 150,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: 10,
		},
	},
	loading: {
		flex: 1,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
	},
}));

const PendingOrders = ({ data, loading }) => {
	const classes = customStyles();
	const sharedClasses = sharedStyles();

	const [pendingOrders, setPendingOrders] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [openCancel, setOpenCancel] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		retrievePendingOrders();
	}, [data]);

	const retrievePendingOrders = async () => {
		const filteredProducts = await data.filter(
			(item) => item?.status === 'Pending'
		);

		setPendingOrders(filteredProducts);
	};

	const onApproveOrder = async (order) => {
		setSubmitting(true);
		const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
			'x'
		);

		const payload = {
			...order,
			status: 'Approved',
			tracking: {
				...order?.tracking,
				[timestamp]: {
					message: 'Order Approved',
					timestamp,
				},
			},
		};

		await firebase
			.database()
			.ref(`orders/${order.id}`)
			.update(payload)
			.then(async () => {
				setSubmitting(false);
				setOpen(true);
				await sendPushNotification(
					order?.buyer?.notification_token,
					'Market',
					`${order?.seller?.store} approved your order for ${order?.product}`,
					{
						screen: 'APP',
						subScreen: 'SHOP',
						data: {},
					}
				);
			})
			.catch((err) => {
				setSubmitting(false);
				console.log('error ', err);
			});
	};

	const onDeclineOrder = async (order) => {
		setSubmitting(true);
		const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
			'x'
		);

		const payload = {
			...order,
			status: 'Declined',
			tracking: {
				...order?.tracking,
				[timestamp]: {
					message: 'Order Declined',
					timestamp,
				},
			},
		};

		await firebase
			.database()
			.ref(`orders/${order.id}`)
			.update(payload)
			.then(async () => {
				setSubmitting(false);
				setOpenCancel(true);
				await sendPushNotification(
					order?.buyer?.notification_token,
					'Market',
					`${order?.seller?.store} declined your order for ${order?.product}`,
					{
						screen: 'APP',
						subScreen: 'SHOP',
						data: {},
					}
				);
			})
			.catch((err) => {
				setSubmitting(false);
				console.log('error ', err);
			});
	};

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handleClose} severity='success'>
					Order is Approved
				</Alert>
			</Snackbar>
			<Snackbar
				open={openCancel}
				autoHideDuration={6000}
				onClose={() => setOpenCancel(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handleClose} severity='error'>
					Order is Cancelled
				</Alert>
			</Snackbar>
			{loading && (
				<div className={classes.loading}>
					<CircularProgress />
				</div>
			)}
			{pendingOrders.length < 1 && !loading ? (
				<EmptyOrder />
			) : (
				pendingOrders.map((item) => (
					<div className={classes.cards} key={item?.id}>
						<div className={sharedClasses.ordersCardStyle}>
							<div className={classes.contentWrapper}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<FiberManualRecordIcon
										classes={{ root: classes.dots }}
										style={{ color: '#FFE200' }}
									/>
									<Typography
										className={classes.tabText}
										style={{ fontSize: 16 }}
									>
										Pending order
									</Typography>
								</div>
								<Typography className={classes.orderId}>
									Order #{item?.id.slice(0, item?.id.indexOf('||'))}
								</Typography>
								<div className={classes.orderDetails}>
									<div className={classes.productDetails}>
										<div className={classes.row}>
											<Typography className={classes.title}>Product</Typography>
											<Typography className={classes.content}>
												{item?.product}
											</Typography>
										</div>
										<div className={classes.row}>
											<Typography className={classes.title}>
												Quantity
											</Typography>
											<Typography className={classes.content}>
												{item?.units}
											</Typography>
										</div>
										<div className={classes.row}>
											<Typography className={classes.title}>Total</Typography>
											<Typography className={classes.content}>
												TZS {(item?.units * item?.price).toLocaleString()}
											</Typography>
										</div>
									</div>
									<div className={classes.productDetails}>
										<div className={classes.row}>
											<Typography className={classes.title}>Client</Typography>
											<Typography className={classes.content}>
												{item?.buyer?.name}
											</Typography>
										</div>
										<div className={classes.row}>
											<Typography className={classes.title}>Phone</Typography>
											<Typography className={classes.content}>
												{item?.address?.phone}
											</Typography>
										</div>
										<div className={classes.row}>
											<Typography className={classes.title}>Address</Typography>
											<Typography className={classes.content}>
												{item?.address?.house &&
													`House no ${item?.address?.house}, `}
												{item?.address?.street && `${item?.address?.street}, `}
												{item?.address?.area && `${item?.address?.area}`}
											</Typography>
										</div>
									</div>
								</div>
								<div>
									<Button
										onClick={() => onApproveOrder(item)}
										fullWidth
										variant='contained'
										loading={submitting}
										disabled={submitting}
										className={classes.submit}
										type='submit'
									>
										{!submitting ? (
											'Approve'
										) : (
											<CircularProgress color='primary' size={20} />
										)}
									</Button>

									<Button
										onClick={() => onDeclineOrder(item)}
										fullWidth
										color='primary'
										variant='outlined'
										loading={submitting}
										disabled={submitting}
										className={classes.cancel}
									>
										{!submitting ? (
											'Decline'
										) : (
											<CircularProgress color='primary' size={20} />
										)}
									</Button>
								</div>
							</div>
							<div className={classes.cardBottom} />
						</div>
					</div>
				))
			)}
		</div>
	);
};

PendingOrders.propTypes = {
	data: PropTypes.any,
	loading: PropTypes.any,
};

export default PendingOrders;
