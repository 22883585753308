import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	img: {
		height: 400,
		width: 400,
		[theme.breakpoints.down('sm')]: {
			height: 150,
			width: 150,
		},
	},
	emptyWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
		shadowColor: '#00000030',
		padding: 30,
		shadowOpacity: 0.3,
		boxShadow:
			'0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)',
		[theme.breakpoints.down('sm')]: {
			height: 40,
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 50,
		},
	},
	title: {
		fontSize: 40,
		fontFamily: 'Poppins',
		color: 'gray',
	},
}));

const EmptyOrder = () => {
	const classes = useStyles();
	return (
		<div className={classes.emptyWrapper}>
			<Typography className={classes.title}>No orders</Typography>
			<img
				src='https://res.cloudinary.com/djqhkyffp/image/upload/v1622016776/undraw_No_data_re_kwbl_s011a9.png'
				alt='empty order'
				className={classes.img}
			/>
		</div>
	);
};

export default EmptyOrder;
