/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import MediaCard from './MediaCard';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		width: '100%',
	},
	title: {
		marginBottom: theme.spacing(2),
		fontWeight: 'bold',
	},
	card: {
		width: 100,
		[theme.breakpoints.down('sm')]: {
			width: 200,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
}));

const VideosContainer = ({ loading, videos, category }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Typography variant='h5' className={classes.title}>
				{category}
			</Typography>
			{loading ? (
				<Grid container spacing={2} width='100%'>
					{Array.from(new Array(3)).map((_, index) => (
						<Grid
							item
							key={index}
							xs={12}
							sm={6}
							md={4}
							style={{
								width: 345,
							}}
						>
							<Skeleton variant='rect' width='100%' height={200} />
							<Skeleton />
							<Skeleton />
						</Grid>
					))}
				</Grid>
			) : (
				<Grid container spacing={2} width='100%'>
					{videos.map((video, index) => (
						<Grid
							item
							key={index}
							sm={12}
							md={6}
							lg={4}
							className={classes.card}
						>
							<MediaCard
								title={video.title}
								description={video.description}
								videoUrl={video.video}
								id={video.id}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</div>
	);
};

VideosContainer.propTypes = {
	loading: PropTypes.bool.isRequired,
};

export default VideosContainer;
