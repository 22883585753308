import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const AuthTextField = withStyles({
	root: {
		'& .MuiOutlinedInput-root': {
			borderRadius: `19px`,
			color: '#1A1A1A',
		},
		input: { height: '40px', color: '#1A1A1A' },
	},
})(TextField);

export default AuthTextField;
