import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { useParams, useHistory, Link as Navigate } from 'react-router-dom';

import TextField from './TextField';
import useStyles from './useStyles';
import ReactPlayer from 'react-player';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import getBase64 from '../../../helpers/getBase64';
import uploadImages from '../../../helpers/upload';
import uploadVideo from '../../../helpers/uploadVideo';
import { Box, CircularProgress, LinearProgress } from '@material-ui/core';
import sharedStyles from '../../shared/styles';
import extractVideoId from '../../../helpers/extractVideoId';

const UploadForm = () => {
	const classes = useStyles();
	const sharedClasses = sharedStyles();

	const { id } = useParams();
	const history = useHistory();

	const [thumbnail, setThumbnail] = useState();
	const [video, setVideo] = useState({ file: null, preview: '' });
	const [createError, setCreateError] = useState();
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(true);
	const [initialValues, setInitialValues] = useState({
		title: '',
		description: '',
		category: '',
		youtubeLink: '',
	});

	const [tags, setTags] = useState([]);

	useEffect(() => {
		const tagsRef = firebase.database().ref('faculties');
		tagsRef.on('value', (snapshot) => {
			const existingTags = snapshot.val();
			if (existingTags) {
				const tagsArray = Object.keys(existingTags).map((key) => ({
					label: key,
					value: key,
				}));
				setTags(tagsArray);
			}
		});
		return () => tagsRef.off();
	}, []);

	const retrieveVideo = () => {
		setLoading(true);
		if (id) {
			try {
				firebase
					.database()
					.ref('videos')
					.on('value', async (snapshot) => {
						const videoArray =
							snapshot.val() !== null
								? Object.entries(snapshot.val()).filter((e) => e[0] === id)
								: [];
						if (videoArray[0][1]) {
							// eslint-disable-next-line no-shadow
							const { title, description, category, video, thumbnail } =
								videoArray[0][1];

							let videoId = null;

							if (video && video.includes('youtube.com')) {
								videoId = extractVideoId(video);
							}

							setInitialValues({
								title,
								description,
								category,
								thumbnail,
								youtubeLink: videoId ? video : '',
							});

							setThumbnail(thumbnail);
							!videoId && setVideo({ url: video });
						}
					});
				setLoading(false);
			} catch (err) {
				console.log(err);
				setLoading(false);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		retrieveVideo();
	}, [id]);

	const playerRef = useRef(null);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object({
			title: Yup.string().required('Title is required'),
			description: Yup.string().required('Description is required'),
			category: Yup.string().required('Category is required'),
			youtubeLink: Yup.string().matches(
				/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([\w-]{11})(?:\S+)?$/,
				'Invalid YouTube video link'
			),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			let videoUrl, thumbnailUrl;
			if (video) {
				try {
					videoUrl = video?.url
						? video?.url
						: await uploadVideo(video, setProgress, setSubmitting);
				} catch (error) {
					console.log(error, 'video');
				}
			}
			if (thumbnail) {
				try {
					if (isValidUrl(thumbnail)) {
						thumbnailUrl = thumbnail;
					} else {
						const result = await getBase64(thumbnail);
						thumbnailUrl = await uploadImages(result);
					}
				} catch (error) {
					console.log(error);
				}
			}

			const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
				'x'
			);

			const VideoId = `${timestamp}||${uuidv4()}`;

			await firebase
				.database()
				.ref(`videos/${id || VideoId}`)
				.set({
					...values,
					timestamp,
					id: id || VideoId,
					expired: false,
					thumbnail: thumbnailUrl || null,
					video: values?.youtubeLink || videoUrl,
				})
				.then(async () => {
					setSubmitting(false);
					history.push('/admins/videos');
				})
				.catch((error) => {
					setSubmitting(false);
					console.log(error.message);
					setCreateError(error.message);
				});
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		const tagsRef = firebase.database().ref('faculties');
		tagsRef.on('value', (snapshot) => {
			const existingTags = snapshot.val();
			if (existingTags) {
				const tagsArray = Object.keys(existingTags).map((key) => ({
					label: key,
					value: key,
				}));
				setTags(tagsArray);
			}
		});
		return () => tagsRef.off();
	}, []);

	const onFileUpload = useCallback((acceptedFiles) => {
		const newFile = acceptedFiles[0];
		if (newFile) {
			setVideo(
				Object.assign(newFile, {
					preview: URL.createObjectURL(newFile),
				})
			);
			formik.setFieldValue('youtubeLink', '');
		}
	}, []);

	const removeVideo = () => {
		setVideo({ file: null, preview: '' });
		playerRef.current.seekTo(0);
	};

	const onThumbnailUpload = (files) => {
		setThumbnail(files[0]);
	};

	return loading ? (
		<div className={classes.loading}>
			<CircularProgress />
		</div>
	) : (
		<div className={classes.addProductWrapper}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.title}>Upload a new video</Typography>
				<form className={classes.form} onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						{!formik.values.youtubeLink && (
							<Grid item xs={12} spacing={2}>
								{!video.preview && !video.url ? (
									<DropzoneArea
										onChange={onFileUpload}
										filesLimit={1}
										acceptedFiles={['video/*']}
										dropzoneText='Drag and drop a video file or click here'
										maxFileSize={3000000000}
									/>
								) : (
									<div
										style={{
											display: 'flex',
											alignContent: 'center',
											justifyContent: 'center',
											width: '100%',
										}}
									>
										<div
											style={{
												gridArea: 'video',
												position: 'relative',
												zIndex: 1,
												maxWidth: '40rem',
											}}
										>
											<ReactPlayer
												ref={playerRef}
												url={video.preview || video.url}
												height='auto'
												width='100%'
												controls
											/>
											<IconButton
												className={classes.deleteButton}
												onClick={removeVideo}
												style={{
													position: 'absolute',
													top: 0,
													right: 0,
													zIndex: 2,
													color: 'white',
												}}
											>
												<DeleteIcon />
											</IconButton>
										</div>
									</div>
								)}
							</Grid>
						)}
						{!video.preview && !video.url && (
							<Grid item xs={12}>
								<TextField
									variant='outlined'
									fullWidth
									id='youtubeLink'
									label='Add Youtube Video Link'
									name='youtubeLink'
									value={formik.values.youtubeLink}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.youtubeLink &&
										Boolean(formik.errors.youtubeLink)
									}
									helperText={
										formik.touched.youtubeLink && formik.errors.youtubeLink
									}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='title'
								label='Title'
								name='title'
								value={formik.values.title}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.title && Boolean(formik.errors.title)}
								helperText={formik.touched.title && formik.errors.title}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='description'
								label='Description'
								name='description'
								multiline
								rows={6}
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description && formik.errors.description
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='category'
								name='category'
								select
								value={formik.values.category}
								SelectProps={{ native: true }}
								error={formik.touched.category && formik.errors.category}
								helperText={
									formik.touched.category && formik.errors.category
										? formik.errors.category
										: null
								}
								{...formik.getFieldProps('category')}
							>
								<option value=''>Select Faculty</option>
								{tags.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						{!formik.values.youtubeLink && (
							<Grid item xs={12}>
								<Typography
									style={{ color: 'gray', marginBottom: 5, marginTop: 10 }}
								>
									Thumbnail
								</Typography>
								<DropzoneArea
									acceptedFiles={['image/*']}
									filesLimit={1}
									dropzoneText='Drag and drop a Thumbnail image here or click'
									onChange={onThumbnailUpload}
									initialFiles={[thumbnail]}
								/>
							</Grid>
						)}
						<Typography
							style={{
								textAlign: 'center',
								color: '#ff0000',
								fontSize: 14,
								marginTop: 10,
							}}
						>
							{createError && createError}
						</Typography>
					</Grid>
					<div>
						<Button
							onClick={() => null}
							fullWidth
							loading={formik.isSubmitting}
							disabled={
								formik.isSubmitting ||
								((!thumbnail || !video) && !formik.values.youtubeLink)
							}
							variant='contained'
							className={classes.submit}
							type='submit'
						>
							{formik.isSubmitting ? 'Uploading...' : 'Upload'}
						</Button>
						<Navigate to='/admins/videos' className={classes.link}>
							<Button
								onClick={() => null}
								fullWidth
								loading={formik.isSubmitting}
								disabled={formik.isSubmitting}
								color='primary'
								variant='outlined'
								className={classes.cancel}
							>
								Cancel
							</Button>
						</Navigate>
					</div>
				</form>
				{formik.isSubmitting && video.preview && (
					<Box display='flex' alignItems='center'>
						<Box width='90%' mr={1}>
							<LinearProgress variant='determinate' value={progress} />
						</Box>
						<Box minWidth={35}>
							<Typography variant='body2' color='textSecondary'>{`${Math.round(
								progress
							)}%`}</Typography>
						</Box>
					</Box>
				)}
			</div>
		</div>
	);
};

export default UploadForm;

function isValidUrl(string) {
	try {
		new URL(string);
		return true;
	} catch (_) {
		return false;
	}
}
