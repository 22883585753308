import React, { useState, useEffect } from 'react';
import { Link as Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './useStyles';
import AuthTextField from './AuthTextField';
import PasswordTextField from './PasswordTextField';
import { useHistory } from 'react-router-dom';
import { useUpdateContext } from '../../data/AppContext';

function Copyright() {
	return (
		<Typography
			variant='body2'
			color='textSecondary'
			style={{ color: '#FFFFFF' }}
			align='center'
		>
			{'Copyright © '}
			<Link color='inherit' href='http://market.mychui.com/'>
				Chui
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}
export default function Login() {
	const classes = useStyles(),
		[submitting, setSubmitting] = useState(false),
		[authError, setAuthError] = useState(),
		logo =
			'https://res.cloudinary.com/djqhkyffp/image/upload/v1629826406/mobivet/inside_1_oyrgrh.png';

	const history = useHistory();

	const update = useUpdateContext();

	useEffect(() => {
		localStorage.removeItem('user');
	}, []);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Required'),
			password: Yup.string().required('Password is required'),
		}),
		onSubmit: async (values) => {
			setAuthError();
			setSubmitting(true);
			await firebase
				.auth()
				.signInWithEmailAndPassword(values.email, values.password)
				.then(async (result) => {
					if (result?.user?.emailVerified) {
						await firebase
							.database()
							.ref(`market_users/${result?.user?.uid}`)
							.once(
								'value',
								async (snapshot) => {
									await localStorage.setItem(
										'user',
										JSON.stringify(snapshot.val())
									);
									const user = snapshot.val();
									update(user);
									if (user?.type === 'admin') {
										history.push('/admins');
									} else {
										if (user?.isVerified) {
											history.push('/home');
										} else {
											history.push('/pending');
										}
									}
								},
								function (errorObject) {
									console.log('The read failed: ' + errorObject.code);
								}
							);
					} else {
						setAuthError('Please verify your email address before login.');
					}
					setSubmitting(false);
				})
				.catch(() => {
					setAuthError('Incorrect email or password.');
					setSubmitting(false);
				});
		},
	});

	return (
		<div className={classes.wrapper} style={{ height: '100vh' }}>
			<Container component='main' maxWidth='xs' className={classes.container}>
				<div className={classes.paper}>
					<img src={logo} alt='chui logo' className={classes.logo} />
					<form className={classes.form} onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<AuthTextField
									variant='outlined'
									required
									fullWidth
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									error={formik.touched.email && formik.errors.email}
									helperText={
										formik.touched.email && formik.errors.email
											? formik.errors.email
											: null
									}
									{...formik.getFieldProps('email')}
								/>
							</Grid>
							<Grid item xs={12}>
								<PasswordTextField
									variant='outlined'
									required
									fullWidth
									name='password'
									label='Password'
									id='password'
									autoComplete='current-password'
									error={formik.touched.password && formik.errors.password}
									helperText={
										formik.touched.password && formik.errors.password
											? formik.errors.password
											: null
									}
									{...formik.getFieldProps('password')}
								/>
							</Grid>
						</Grid>
						<Typography
							style={{
								textAlign: 'center',
								color: '#ff0000',
								fontSize: 14,
								marginTop: 10,
							}}
						>
							{authError && authError}
						</Typography>
						<Navigate to='/reset-password' className={classes.link}>
							<Typography className={classes.optionsText}>
								Forgot your password?
							</Typography>
						</Navigate>
						<Button
							onClick={() => null}
							fullWidth
							loading={submitting.toString()}
							disabled={submitting}
							variant='contained'
							className={classes.submit}
							type='submit'
						>
							{!submitting ? (
								'Login'
							) : (
								<CircularProgress color='primary' size={20} />
							)}
						</Button>
						<div className={classes.registered}>
							<Typography style={{ textAlign: 'center' }} color='textSecondary'>
								Don't have an account yet?&nbsp;
							</Typography>
							<Navigate to='/signup' className={classes.link}>
								<Typography
									style={{ marginTop: 0 }}
									className={classes.optionsText}
								>
									Sign Up!
								</Typography>
							</Navigate>
						</div>
					</form>
				</div>
			</Container>
			<Box>
				<Copyright />
			</Box>
		</div>
	);
}
