import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Link as Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import TextField from './TextField';
import { useHistory } from 'react-router-dom';
import sharedStyles from '../../shared/styles';
import uploadImages from '../../../helpers/upload';
import getBase64 from '../../../helpers/getBase64';
import { useAppContext } from '../../../data/AppContext';

export default function AddProduct() {
	const classes = useStyles(),
		[submitting, setSubmitting] = useState(false),
		[createError, setCreateError] = useState();

	const user = useAppContext();

	const [images, setImages] = useState();

	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			name: '',
			units: '',
			price: '',
			description: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Required'),
			units: Yup.number().required('Required'),
			price: Yup.string().required('Required'),
			description: Yup.string()
				.min(20, 'Must be 20 characters or more')
				.required('Required'),
		}),
		onSubmit: async (values) => {
			setCreateError();
			setSubmitting(true);

			let imagesUrl = [];

			if (images.length > 0) {
				try {
					const result = await Promise.all(
						images.map(async (image) => {
							const item = await getBase64(image);
							return item;
						})
					);

					imagesUrl = await Promise.all(
						result.map(async (image) => {
							const url = await uploadImages(image);
							return url;
						})
					);
				} catch (error) {
					console.log(error);
				}
			}

			const timestamp = moment(moment(), 'ddd, DD MMM YYYY HH:mm:ss ZZ').format(
				'x'
			);

			const productId = `${timestamp}||${uuidv4()}`;
			await firebase
				.database()
				.ref(`products/${productId}`)
				.set({
					...values,
					timestamp,
					id: productId,
					availability: true,
					images: imagesUrl
						? imagesUrl
						: [
								'https://res.cloudinary.com/djqhkyffp/image/upload/v1621323090/drug_hxbibc.png',
						  ],
					seller: user,
				})
				.then(async () => {
					history.push('/home');
					setSubmitting(false);
				})
				.catch((error) => {
					console.log(error.message);
					setCreateError(error.message);
					setSubmitting(false);
				});
		},
	});

	const onImageUpload = async (files) => {
		await setImages(files);
	};

	const sharedClasses = sharedStyles();

	return (
		<div className={classes.addProductWrapper}>
			<div className={sharedClasses.containerStyle}>
				<Typography className={classes.title}>Add a product</Typography>
				<form className={classes.form} onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='name'
								label='Product Name'
								name='name'
								autoComplete='name'
								error={formik.touched.name && formik.errors.name}
								helperText={
									formik.touched.name && formik.errors.name
										? formik.errors.name
										: null
								}
								{...formik.getFieldProps('name')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='units'
								label='Number of Units'
								type='number'
								name='units'
								error={formik.touched.units && formik.errors.units}
								helperText={
									formik.touched.units && formik.errors.units
										? formik.errors.units
										: null
								}
								{...formik.getFieldProps('units')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								id='price'
								label='Unit Price'
								type='number'
								name='price'
								error={formik.touched.price && formik.errors.price}
								helperText={
									formik.touched.price && formik.errors.price
										? formik.errors.price
										: null
								}
								{...formik.getFieldProps('price')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								name='description'
								label='Description'
								type='text'
								id='description'
								multiline
								rows={6}
								error={formik.touched.description && formik.errors.description}
								helperText={
									formik.touched.description && formik.errors.description
										? formik.errors.description
										: null
								}
								{...formik.getFieldProps('description')}
							/>
						</Grid>
						<Grid item xs={12}>
							<DropzoneArea
								acceptedFiles={['image/*']}
								dropzoneText={'Drag and drop a product image here or click'}
								onChange={(files) => onImageUpload(files)}
								style={{ borderColor: '#ED7922' }}
							/>
						</Grid>
					</Grid>
					<Typography
						style={{
							textAlign: 'center',
							color: '#ff0000',
							fontSize: 14,
							marginTop: 10,
						}}
					>
						{createError && createError}
					</Typography>
					<div>
						<Button
							onClick={() => null}
							fullWidth
							loading={submitting}
							disabled={submitting}
							variant='contained'
							className={classes.submit}
							type='submit'
						>
							{!submitting ? (
								'Add Product'
							) : (
								<CircularProgress color='primary' size={20} />
							)}
						</Button>
						<Navigate to='/home' className={classes.link}>
							<Button
								onClick={() => null}
								fullWidth
								loading={submitting.toString()}
								disabled={submitting}
								color='primary'
								variant='outlined'
								className={classes.cancel}
							>
								Cancel
							</Button>
						</Navigate>
					</div>
				</form>
			</div>
		</div>
	);
}
